const ENV_PREFIX = "REACT_APP_";
const ENV_PREFIX_LENGTH = ENV_PREFIX.length;
let _adapterProps = {};

class AppEnvClass {
  private _userManaged = {};
  private _initProps = Object.fromEntries(
    Object.keys(process.env)
      .filter((k) => k.startsWith(ENV_PREFIX))
      .map((k) => [k.substring(ENV_PREFIX_LENGTH), process.env[k]]),
  );

  setUserProp = (prop: string, val: string | null | undefined) => {
    this._userManaged[prop] = val;
  };

  getProp = (prop: string): string | null | undefined => {
    if (Object.keys(this._userManaged).includes(prop)) {
      return this._userManaged[prop];
    } else if (Object.keys(_adapterProps).includes(prop)) {
      return _adapterProps[prop];
    } else if (Object.keys(this._initProps).includes(prop)) {
      return this._initProps[prop];
    }
    return undefined;
  };

  deleteUserProp = (prop: string) => {
    delete this._userManaged[prop];
  };
}

const getAppEnvClass = new AppEnvClass();
const ProxyHandler = {
  get(target: object, prop: string) {
    const value = getAppEnvClass.getProp(prop);
    return value;
  },

  set(target: object, prop: string, value: string | null | undefined) {
    getAppEnvClass.setUserProp(prop, value);
    return true;
  },

  deleteProperty(target: object, prop: string) {
    getAppEnvClass.deleteUserProp(prop);
    return true;
  },
};

const AppEnv: any = new Proxy({}, ProxyHandler);
(global as any)._SM_Env_Context = AppEnv;

export const setAdapterProps = (props: Record<string, string>) => {
  _adapterProps = props;
};

export default AppEnv;
