import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { headerHeight, defaultDrawerWidth } from "./constants";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: `calc(100vh - ${headerHeight}px)`,
      position: "relative",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawer: {
      flexShrink: 0,
    },
    resizable: {
      width: "5px",
      cursor: "ew-resize",
      padding: "4px 0 0",
      borderTop: `1px solid ${theme.palette.divider}`,
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
      backgroundColor: theme.palette.background.default,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    drawerHeaderIcon: {
      height: 45,
      width: 45,
      marginRight: 5,
    },
    main: {
      marginLeft: -defaultDrawerWidth,
      width: "100%",
      height: "100%",
      overflowX: "auto",
      overflowY: "hidden",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    mainShift: {
      marginLeft: 0,
    },
    content: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexGrow: 1,
      padding: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    controlPanel: {
      width: "170px",
      flexShrink: 0,
      backgroundColor: theme.palette.background.paper,
      padding: "0 6px",
      paddingBottom: "5px",
      overflowX: "hidden",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: theme.spacing(1, 3),
      overflow: "hidden",
    },
    hide: {
      display: "none",
    },
    arrowIcons: {
      width: 20,
      height: 20,
    },
  }),
);
