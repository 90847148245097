import { useSelector } from "react-redux";
import { isProfileAccessible } from "../../services/UserService";
import {
  selectCallerAccounts,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";
import InteractiveMenu from "../common/InteractiveMenu";
//import MenuItemWithSubItems from "./MenuItemWithSubItems";
//import { useThemeContext } from "../../themeContext";

const MenuUI = ({ anchor, selected, handleClose }) => {
  const isMenuOpen = Boolean(anchor);
  const profileAccessible = isProfileAccessible();
  const callerAccounts = useSelector(selectCallerAccounts);
  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );
  const isProductBadgeEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "badgeOverlayEnabled"),
  );

  const RenderMenu = () => {
    //const { toggleTheme } = useThemeContext();
    // const subMenuHandle = (item) => {
    //   console.log("item new method", item);
    //   switch (item) {
    //     case "Light":
    //       //toggleTheme(item);
    //       handleClose();
    //       break;
    //     case "Classic":
    //       //toggleTheme(item);
    //       handleClose();
    //       break;
    //     default:
    //       break;
    //   }
    // };

    // const menuItems = [
    //   {
    //     id: 1,
    //     primaryText: "Theme",
    //     subItems: ["Light", "Classic"],
    //     onSubMenuItemClick: subMenuHandle,
    //   },
    // ];
    return (
      <InteractiveMenu
        menuId="RenderMenu"
        open={isMenuOpen}
        anchorEl={anchor}
        handleClose={handleClose}
        profileAccessible={profileAccessible}
        callerAccounts={callerAccounts}
      />
    );
  };

  const RenderConfigMenu = () => {
    return (
      <InteractiveMenu
        menuId="RenderConfigMenu"
        open={isMenuOpen}
        anchorEl={anchor}
        handleClose={handleClose}
        profileAccessible={profileAccessible}
        isProductAlertsEnabled={isProductAlertsEnabled}
        isProductBadgeEnabled={isProductBadgeEnabled}
        callerAccounts={callerAccounts}
      />
    );
  };

  const RenderCreateRuleMenu = () => {
    return (
      <InteractiveMenu
        menuId="RenderMenu"
        open={isMenuOpen}
        anchorEl={anchor}
        handleClose={handleClose}
        profileAccessible={profileAccessible}
        isProductAlertsEnabled={isProductAlertsEnabled}
        callerAccounts={callerAccounts}
      />
    );
  };

  return (
    <div>
      {selected === "config-menu" && <RenderConfigMenu />}
      {selected === "profile" && <RenderMenu />}
      {selected === "create-new-rule" && <RenderCreateRuleMenu />}
    </div>
  );
};

export default MenuUI;
