import { takeLatest, put } from "typed-redux-saga/macro";
import { addGlobalAlertState } from "./GlobalAlertActions";
import { openGlobalAlertAction } from "./GlobalAlertTypes";
import {
  errorMessagesMap,
  ignoredErrorMessages,
} from "./GlobalAlertErrorMessages";
import { isErrorIgnored } from "../../utils/GlobalAlertUtil";

function* displayErrorGlobalAlert(action: openGlobalAlertAction) {
  if (action.showGenericError !== false) {
    let errorMessage = "Something is wrong. Please try again later.";
    if (action.payload && action.payload.message) {
      errorMessage = action.payload.message;
    } else if (action.payload && action.payload.errors) {
      errorMessage = action.payload.errors[0].message;
    } else if (action.message) {
      errorMessage = action.message;
    }

    // ignore subcription errors that have specific ignored error messages
    if (isErrorIgnored(errorMessage, ignoredErrorMessages)) {
      return;
    }

    if (
      (action.type === "FETCH_PRODUCT_IDS_FAILURE" ||
        action.type === "ADD_PRODUCTS_TO_CATEGORIES_FAILURE") &&
      !action.message
    ) {
      return null;
    } else if (action.type === "FETCH_JOBS_LIST_FAILURE") {
      return null;
    } else if (
      action.type === "FETCH_VARIATION_GROUP_IDS_FAILURE" &&
      !action.message
    ) {
      return null;
    } else if (
      action.type === "FETCH_VARIATION_GROUP_DETAILS_BY_GROUP_ID_FAILURE" &&
      !action.message
    ) {
      return null;
    } else if (
      action.type === "DELETE_VARIATION_GROUP_BY_GROUP_ID_FAILURE" &&
      !action.message
    ) {
      return null;
    } else if (
      action.type === "UPDATE_VARIATION_GROUP_BY_GROUP_ID_FAILURE" &&
      !action.message
    ) {
      return null;
    } else if (
      action.type === "FETCH_CLUSTER_REQUEST_BY_CATEGORY_FAILURE" &&
      !action.message
    ) {
      return null;
    } else if (
      action.type === "SAVE_AUTOMATION_RULES_DETAILS_FAILURE" ||
      action.type === "UPDATE_AUTOMATION_RULES_DETAILS_BY_RULE_ID_FAILURE"
    ) {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: {
                id: "globalAlertOperations.automationRulesError",
                defaultMessage: errorMessage,
              },
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    } else {
      yield* put(
        addGlobalAlertState({
          alertsProps: [
            {
              descriptor: errorMessagesMap[action.type],
              message: errorMessage,
              severity: "error",
              variant: "standard",
            },
          ],
        }),
      );
    }
  } else {
    return;
  }
}

export function* watchErrorGlobalAlert() {
  yield* takeLatest(
    (action: any) => action.type.endsWith("FAILURE"),
    displayErrorGlobalAlert,
  );
}
