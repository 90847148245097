import {
  AppConfigObject,
  AppStoreConfiguration,
} from "../../utils/AppConfigUtils";
import { createUserAccountAction } from "./AppConfigActions";
import {
  AppConfigDetails,
  CallerAccountInfoObject,
  FETCH_APP_CONFIG,
  FETCH_CALLER_ACCOUNTS,
  SET_ACCOUNT_ID,
  storeAndAccountDetails,
} from "./AppConfigTypes";

export interface AppConfigState {
  isFetched: boolean;
  data: AppConfigDetails;
  storeAndAccountConfigDetails: storeAndAccountDetails;
  ecomPlatformType: string;
  accountId: string;
  callerAccounts: CallerAccountInfoObject;
  isCallerAccountsInvoked: boolean;
  isFetchingCallerAccountsFailed: boolean;
}

const initialState: AppConfigState = {
  isFetched: false,
  data: {
    account: {
      accountId: "",
      name: "",
      logo: "",
      inventoryThreshold: "",
      createdAt: "",
      updatedAt: "",
      enableProductAlerts: false,
      enableBoostBury: "",
      enableSubstitution: "",
      productImagePrefix: "",
      badgeOverlayEnabled: false,
    },
    stores: [],
  },
  storeAndAccountConfigDetails: {},
  ecomPlatformType: "",
  accountId: "",
  callerAccounts: {
    accounts: [],
  },
  isCallerAccountsInvoked: false,
  isFetchingCallerAccountsFailed: false,
};

export const AppConfigReducer = (
  state: AppConfigState = initialState,
  action,
): AppConfigState => {
  const data = action.payload;
  switch (action.type) {
    case FETCH_APP_CONFIG.SUCCESS:
      const defaultConfigs: AppConfigObject = { defaults: {} };
      const storeConfigs: AppConfigObject = {};
      const accountConfig = Object.entries(data.account).map((details) => {
        return {
          configId: details[0],
          value: details[1],
        };
      });
      const storeConfig: AppStoreConfiguration[] = [];
      data.stores?.forEach((storeDetails) => {
        const storeId = storeDetails.storeId;
        Object.entries(storeDetails).forEach((details) => {
          storeConfig.push({
            storeId: storeId,
            configId: details[0],
            value: details[1],
          });
        });
      });

      if (accountConfig.length > 0) {
        accountConfig.forEach((config) => {
          defaultConfigs.defaults = {
            ...defaultConfigs.defaults,
            [config.configId]: config.value,
          };
        });
      }
      if (storeConfig.length > 0) {
        storeConfig.forEach((config) => {
          storeConfigs[config.storeId] = {
            ...storeConfigs[config.storeId],
            [config.configId]: config.value,
          };
        });
      }
      return {
        ...state,
        isFetched: true,
        data,
        storeAndAccountConfigDetails: {
          ...storeConfigs,
          ...defaultConfigs,
        },
      };
    case FETCH_CALLER_ACCOUNTS.REQUEST: {
      return {
        ...state,
        isFetchingCallerAccountsFailed: false,
      };
    }
    case FETCH_CALLER_ACCOUNTS.SUCCESS: {
      return {
        ...state,
        callerAccounts: data,
        isCallerAccountsInvoked: true,
      };
    }
    case FETCH_CALLER_ACCOUNTS.FAILURE: {
      return {
        ...state,
        isFetchingCallerAccountsFailed: true,
      };
    }
    case SET_ACCOUNT_ID: {
      const {
        payload: { accountId },
      } = action as ReturnType<typeof createUserAccountAction>;
      return {
        ...state,
        accountId: accountId,
      };
    }
    default:
      return state;
  }
};
