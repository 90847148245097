import { Typography } from "@material-ui/core";
import { ImageList, Stack, styled, CircularProgress } from "@mui/material";
import {
  getProductLeadingColorImageSFCC,
  getProductModalTitle,
} from "../../utils/ProductUtil";
import { selectProductLeadingImage } from "../../store/product/ProductSelectors";
import React, { useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../store/AppState";
import {
  removeLeadingImageForProductInCategory,
  setLeadingImageForProductInCategory,
  getLeadingImageForProductInCategory,
  getLeadingImages,
  getVariationAttributes,
  setSelectedFilterValueAction,
  resetFiltersAction,
  resetVariationAttributesForProduct,
} from "../../store/leading-image/LeadingImageActions";
import {
  selectLeadingImageIdForProductInCategory,
  selectLeadingImageUrlForProductInCategory,
  selectIsRefreshing,
  selectIsSettingLeadingImage,
} from "../../store/leading-image/LeadingImageSelectors";
import { CloseModal, setModalState } from "../../store/modal/ModalActions";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { selectProductFetched } from "../../store/product/ProductSelectors";
import { generalText } from "../../utils/GeneralText";
import { ProductImage } from "../../store/leading-image/LeadingImageTypes";
import {
  selectProductImages,
  selectVariationAttributes,
  selectFilterValue,
  selectIsRefreshingLeadingImageModal,
  selectIsVariationAttributesCallInvoked,
} from "../../store/leading-image/LeadingImageSelectors";
import { StyledDiv, styleClasses } from "./LeadingImageModalStyles";
import ImageListItems from "../ImageListItems";
import { getProductColorSFCC } from "../../utils/ProductUtil";
import { selectLeadingColorForProduct } from "../../store/color-management/ColorManagementSelectors";
import CustomDialog from "../common/DialogContainer";
import DropdownMenu from "../common/DropdownMenu";
import ButtonComponent from "../common/ButtonComponent";

interface Props {
  categoryId: string;
}

const LeadingImage = styled("img")`
  width: 100%;
`;

const text = {
  ...defineMessages({
    modalTitle: {
      id: "leadingImageModal.modalTitle",
      defaultMessage: "Image Mgmt",
    },
    removeLeadingImage: {
      id: "leadingImageModal.removeLeadingImage",
      defaultMessage: "removeLeadingImage",
    },
    filterBy: {
      id: "leadingImageModal.filterBy",
      defaultMesssage: "Filter by",
    },
    disableDropDown: {
      id: "LeadingImageModal.disableDropdown",
      defaultMessage:
        "The dropdown is disabled because a leading color is selected for this product.",
    },
  }),
  ...generalText,
};

export const LeadingImageModalId = "LeadingImageModal";

const LeadingImageModal: React.FC<Props> = (props) => {
  const { categoryId } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLeadingColor, setIsLeadingColor] = useState(false);

  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, LeadingImageModalId, "productId"),
  );

  const leadingImage: string | null =
    useSelector((state: AppState) =>
      selectLeadingImageIdForProductInCategory(state, productId, categoryId),
    ) ?? null;
  const leadingImageThumbnail: string | null =
    useSelector((state: AppState) =>
      selectLeadingImageUrlForProductInCategory(state, productId, categoryId),
    ) ?? null;

  let filters = useSelector(
    (state: AppState) => selectFilterValue(state, productId, categoryId) ?? {},
  );
  const product = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, LeadingImageModalId),
  );
  const isRefreshing = useSelector(selectIsRefreshing);
  const isRefreshingLeadingImageModal = useSelector(
    selectIsRefreshingLeadingImageModal,
  );
  const isVariationAttributesCallInvoked = useSelector(
    selectIsVariationAttributesCallInvoked,
  );
  const images = useSelector(selectProductImages);
  const baseProductUpdatedLeadingColor = useSelector((state: AppState) =>
    selectLeadingColorForProduct(state, productId, categoryId),
  );

  const attributes = useSelector(selectVariationAttributes);
  const productLeadingImage = useSelector((state: AppState) =>
    selectProductLeadingImage(state, productId, categoryId),
  );
  const productLeadingColorImage =
    product && getProductLeadingColorImageSFCC(product, categoryId);

  const isSettingLeadingImage = useSelector(selectIsSettingLeadingImage);

  useEffect(() => {
    const category = product?.categorySpecific.find(
      (item) => item.categoryId === categoryId,
    );
    const hasLeadingColor = category?.leadingColorId ? true : false;
    setIsLeadingColor(hasLeadingColor);
  }, [product, categoryId]);

  useEffect(() => {
    if (
      !images.length &&
      product != null &&
      !leadingImage &&
      !isRefreshing &&
      !isVariationAttributesCallInvoked
    ) {
      dispatch(
        getLeadingImageForProductInCategory({
          categoryId: categoryId,
          product: product,
          selectedColorId: "",
        }),
      );
      dispatch(
        getVariationAttributes({
          baseProductId: product?.variationBaseId ?? productId,
        }),
      );
    } else if (
      product !== null &&
      !images.length &&
      !isRefreshing &&
      !isVariationAttributesCallInvoked
    ) {
      dispatch(
        getLeadingImages({
          product: product,
        }),
      );
      dispatch(
        getVariationAttributes({
          baseProductId: product?.variationBaseId ?? productId,
        }),
      );
    } else if (product !== null && !isVariationAttributesCallInvoked) {
      dispatch(
        getVariationAttributes({
          baseProductId: product?.variationBaseId ?? productId,
        }),
      );
    }
  }, [
    dispatch,
    product,
    images,
    categoryId,
    productId,
    leadingImage,
    isRefreshing,
    isVariationAttributesCallInvoked,
  ]);

  const attrVariationFilterData = useMemo(
    () =>
      attributes?.filter((attribute) => {
        return images?.some((image) =>
          image.variationAttributes?.some(
            (imgVariationAttr) =>
              attribute.attributeId === imgVariationAttr.attributeId,
          ),
        );
      }),
    [attributes, images],
  );

  const imageAttrData = useMemo(
    () =>
      attrVariationFilterData?.map((attribute) => {
        return {
          ...attribute,
          values: attribute.values
            ?.filter((value) =>
              product?.colors
                ? product?.colors.find((color) => color.name === value.name)
                    ?.isPublished
                : value,
            )
            .filter((attrValue) =>
              images?.some((image) =>
                image.variationAttributes?.some(
                  (imageAttr) =>
                    attribute.attributeId === imageAttr.attributeId &&
                    imageAttr.values?.some(
                      (imgValue) => imgValue.valueId === attrValue.value,
                    ),
                ),
              ),
            ),
        };
      }),
    [attrVariationFilterData, images, product?.colors],
  );

  const baseProductPreselectedColor = useMemo(
    () =>
      getProductColorSFCC(
        baseProductUpdatedLeadingColor,
        product,
        categoryId,
        imageAttrData,
      ),
    [product, categoryId, imageAttrData, baseProductUpdatedLeadingColor],
  );
  if (Object.keys(filters).length === 0 && baseProductPreselectedColor) {
    filters = baseProductPreselectedColor;
  }

  const allPublishedImages =
    images?.length > 0
      ? images.filter(
          (image) =>
            image.variationAttributes?.some((attr) =>
              attr.attributeId === product?.colorVariationAttributeId
                ? attr.values?.some(
                    (value) =>
                      product?.colors?.find(
                        (color) => color.colorId === value.valueId,
                      )?.isPublished,
                  )
                : true,
            ) ?? true,
        )
      : [];

  const filteredImages =
    allPublishedImages?.length > 0
      ? allPublishedImages.filter((image) => {
          return Object.entries(filters).reduce(
            (prev, [attributeId, value]) =>
              prev &&
              (image.variationAttributes
                ?.find((a) => a.attributeId === attributeId)
                ?.values.some((v) => v.valueId === value) ??
                false),
            true,
          );
        })
      : [];

  const handleClose = () => {
    dispatch(setModalState(LeadingImageModalId, false));
    dispatch(CloseModal({ productId: productId }));
    dispatch(resetVariationAttributesForProduct());
    dispatch(resetFiltersAction());
  };

  const filterBy = (attributeId: string, value: string | unknown) => {
    dispatch(
      setSelectedFilterValueAction({
        attributeId,
        filterValue: String(value),
        productId,
        categoryId,
      }),
    );
  };

  const handleImageClick = (image: ProductImage) => {
    dispatch(
      setLeadingImageForProductInCategory({
        productId,
        categoryId,
        image,
      }),
    );
  };

  const handleRemoveClick = () => {
    dispatch(
      removeLeadingImageForProductInCategory({
        productId,
        categoryId,
      }),
    );
  };

  const appliedLeadingImage =
    leadingImage && leadingImageThumbnail
      ? leadingImageThumbnail
      : productLeadingImage
        ? productLeadingImage
        : productLeadingColorImage
          ? productLeadingColorImage
          : product?.colors?.find((color) => color.isPublished)?.thumbnail
            ? product?.colors?.find((color) => color.isPublished)?.thumbnail
            : product?.thumbnail;

  const productModalTitle = getProductModalTitle(product);
  const leadingImageModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage(text.modalTitle)} - {productModalTitle}
      </Typography>
    );
  };
  const leadingImageModalContent = () => {
    return (
      <>
        {isRefreshingLeadingImageModal ? (
          <StyledDiv className={styleClasses.overlay}>
            <CircularProgress />
          </StyledDiv>
        ) : (
          <Stack direction={"row"} spacing={10}>
            <Stack
              sx={{
                width: "300px",
                textAlign: "center",
              }}
              spacing={2}
            >
              {isRefreshing ? (
                <CircularProgress style={{ margin: "auto" }} />
              ) : (
                <LeadingImage src={appliedLeadingImage} alt={product?.code} />
              )}
              <ButtonComponent
                color="primary"
                variant="contained"
                justifyContent="center"
                padding="15px"
                onClick={handleRemoveClick}
                disabled={!leadingImage}
              >
                {intl.formatMessage(text.removeLeadingImage)}
              </ButtonComponent>
              {isLeadingColor ? (
                <Typography variant="caption" style={{ color: "red" }}>
                  {intl.formatMessage(text.disableDropDown)}
                </Typography>
              ) : (
                <></>
              )}
              {product?.variationBaseId && imageAttrData.length > 0 ? (
                <Typography variant="h6">
                  {intl.formatMessage(text.filterBy)}:
                </Typography>
              ) : (
                <></>
              )}
              <Stack>
                {isSettingLeadingImage ? (
                  <StyledDiv className={styleClasses.overlay}>
                    <CircularProgress />
                  </StyledDiv>
                ) : null}
                {product?.variationBaseId &&
                  imageAttrData?.map((attribute) => (
                    <DropdownMenu
                      menuId="LeadingImageModal"
                      attribute={attribute}
                      filters={filters}
                      filterBy={filterBy}
                      isLeadingColor={isLeadingColor}
                      product={product}
                    />
                  ))}
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <ImageList
                sx={{ width: "700px", height: "560px" }}
                cols={4}
                rowHeight={176}
                gap={10}
              >
                <ImageListItems
                  imageList={
                    Object.values(filters).find((e) => e !== "None")
                      ? filteredImages
                      : allPublishedImages
                  }
                  handleImageClick={handleImageClick}
                  leadingImage={leadingImage}
                />
              </ImageList>
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  const leadingImageModalActions = () => {
    return (
      <StyledDiv className={styleClasses.btnCloseDiv}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
          disabled={isRefreshingLeadingImageModal}
        >
          {intl.formatMessage(text.close)}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={leadingImageModalTitle()}
      actions={leadingImageModalActions()}
      fullWidth
      maxWidth={"lg"}
    >
      {leadingImageModalContent()}
    </CustomDialog>
  );
};

export default React.memo(LeadingImageModal);
