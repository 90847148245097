import { combineReducers } from "redux";
import AppState from "./AppState";
import { ProductListReducer } from "./product-list/ProductListReducers";
import { ProductReducer } from "./product/ProductReducers";
import { StoreListReducer } from "./store-list/StoreListReducers";
import { CategoryReducer } from "./category/CategoryReducers";
import { CatalogListReducer } from "./catalog/CatalogReducers";
import { ModalReducer } from "./modal/ModalReducers";
import { OverlayReducer } from "./overlay/OverlayReducers";
import { FindProductsReducer } from "./find-products/FindProductsReducers";
import { globalAlertReducer } from "./global-alert/GlobalAlertReducers";
import { FacetReducer } from "./facets/FacetReducers";
import { UserReducer } from "./user/UserReducers";
import { ReportReducer } from "./report/ReportReducers";
import { BusinessRulesListReducer } from "./businessRules-list/BusinessRulesListReducers";
import { LoaderReducer } from "./loader/LoaderReducers";
import { AddProductCategoriesReducer } from "./add-product-categories/AddProductCategoriesReducer";
import { ProductCategoriesReducer } from "./remove-product-categories/RemoveProductCategoriesReducer";
import { AppConfigReducer } from "./app-config/AppConfigReducers";
import { VariationManagementReducer } from "./variation-management/VariationManagementReducers";
import { ClipBoardReducer } from "./clipboard/ClipBoardReducers";
import { ColorManagementReducer } from "./color-management/ColorManagementReducers";
import { LeadingImageReducer } from "./leading-image/LeadingImageReducer";
import { SocialAnalyticsReducer } from "./product-social-analytics/SocialAnalyticsReducers";
import { AdapterConfigReducer } from "./adapter-config/AdapterConfigReducers";
import { ProductAlertsListReducer } from "./product-alerts-modal/ProductAlertsListReducers";
import { ProductAlertsScheduleReducer } from "./product-alerts-schedule-modal/ProductAlertsScheduleReducers";
import { ProductClusterListReducer } from "./ai-cluster/AiClusterReducers";
import {
  ProductBadgeReducer,
  ProductQueryBadgeReducer,
} from "./product-badge/ProductBadgeReducers";

const rootReducer = combineReducers<AppState>({
  productList: ProductListReducer,
  products: ProductReducer,
  stores: StoreListReducer,
  catalog: CatalogListReducer,
  category: CategoryReducer,
  modal: ModalReducer,
  globalAlert: globalAlertReducer,
  overlay: OverlayReducer,
  findProducts: FindProductsReducer,
  facets: FacetReducer,
  user: UserReducer,
  report: ReportReducer,
  businessRulesList: BusinessRulesListReducer,
  loader: LoaderReducer,
  treeData: AddProductCategoriesReducer,
  productCategories: ProductCategoriesReducer,
  appConfig: AppConfigReducer,
  variationManagement: VariationManagementReducer,
  clipBoard: ClipBoardReducer,
  colorManagementProductList: ColorManagementReducer,
  leadingImage: LeadingImageReducer,
  socialAnalytics: SocialAnalyticsReducer,
  adapterConfig: AdapterConfigReducer,
  productAlerts: ProductAlertsListReducer,
  productAlertsSchedule: ProductAlertsScheduleReducer,
  productsCluster: ProductClusterListReducer,
  productBadge: ProductBadgeReducer,
  queryProductBadge: ProductQueryBadgeReducer,
});

export default rootReducer;
