import { styled } from "@mui/material";

export const classes = {
  divActionBtn: "divActionBtn",
};

export const StyledDiv = styled("div")((theme) => ({
  [`&.${classes.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
    margin: "20px",
  },
}));

export const styles = () => {
  return {
    headerStyle: {
      display: "flex",
      justifyContent: "space-between",
      margin: "5px 0px",
    },
    exportLinkStyle: {
      textDecoration: "none",
      fontSize: "10px",
      lineHeight: "22.4px",
      fontWeight: 700,
      fontFamily: "Helvetica",
    },
  };
};
