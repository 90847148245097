const STORE_TOKEN_KEY = "sm4-token";

export function getToken() {
  const token = localStorage.getItem(STORE_TOKEN_KEY);

  return token;
}

export function setToken(token: string) {
  localStorage.setItem(STORE_TOKEN_KEY, token);
}

export function removeToken() {
  localStorage.removeItem(STORE_TOKEN_KEY);
}

export function isTokenSet() {
  return !!localStorage.getItem(STORE_TOKEN_KEY);
}
