import React, { SyntheticEvent } from "react";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import TextfieldWrapper from "../containers/Textfield/TextFieldWrapper";

interface AutoCompleteProps {
  value: string;
  onChange: (_: SyntheticEvent, newValue: string | null) => void;
  onInputChange?: (value: string) => void;
  options: string[];
  label: string;
  name: string;
  loading?: boolean;
  disabled?: boolean;
  noOptionsText?: string;
  disablePortal?: boolean;
  size?: "small" | "medium";
  isAttributeForm?: boolean;
}

const styles = {
  attributeSearch: {
    width: "324px !important",
    marginRight: "10px !important",
  },
};

const AutoComplete: React.FC<AutoCompleteProps> = ({
  value,
  onChange,
  onInputChange,
  options,
  label,
  name,
  loading = false,
  disabled = false,
  noOptionsText = "",
  disablePortal = true,
  size = "small",
  isAttributeForm,
}) => {
  return (
    <MuiAutocomplete
      disablePortal={disablePortal}
      value={value}
      size={size}
      noOptionsText={noOptionsText}
      loading={loading}
      disabled={disabled}
      onChange={onChange}
      options={options}
      sx={isAttributeForm ? styles.attributeSearch : {}}
      renderInput={(params) => (
        <TextfieldWrapper
          {...params}
          label={label}
          name={name}
          type="text"
          variant="outlined"
          size={size}
          onChange={(event) => onInputChange?.(event.target.value)}
        />
      )}
    />
  );
};

export default AutoComplete;
