import { call, select, takeEvery } from "typed-redux-saga/macro";

import {
  FETCH_PRODUCT_ALERTS_LIST,
  FETCH_PRODUCTS_DETAILS,
  productAlertsList,
  SKU,
} from "./ProductAlertsListType";

import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";

import { getData } from "../../services/ApiService";
import { getUserEmail } from "../../services/UserService";

function* getProductAlertsList() {
  try {
    const actionType = FETCH_PRODUCT_ALERTS_LIST;
    const constName = Object.keys({ FETCH_PRODUCT_ALERTS_LIST })[0].toString();
    const email = yield* select(getUserEmail);
    const endpoint = acquireEndpoint(constName, email);
    const result = yield* call(callApi, actionType, getData, null, endpoint);

    if (result && result.type === actionType.SUCCESS) {
      yield* call(
        fetchProductDetails,
        result.payload.results as productAlertsList[],
      );
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* fetchProductDetails(productAlertsList: productAlertsList[]) {
  const storeProductMap = new Map<string, string[]>();
  try {
    productAlertsList?.forEach((productAlert: productAlertsList) => {
      productAlert.SKUs?.forEach((sku: SKU) => {
        // Only set if not already mapped, so all products from same store get mapped to same store ID
        if (!storeProductMap.has(sku.storeId)) {
          storeProductMap.set(sku.storeId, [sku.productId]);
        } else {
          storeProductMap.get(sku.storeId)?.push(sku.productId);
        }
      });
    });

    if (storeProductMap.size > 0) {
      // Remove duplicate product IDs for each store
      storeProductMap.forEach((productIds, storeId) => {
        storeProductMap.set(storeId, [...new Set(productIds)]);
      });
      for (const [storeId, productIds] of storeProductMap.entries()) {
        const commaSeperatedStoreProductIds =
          productIds.length > 1 ? productIds.join(",") : productIds[0];

        if (commaSeperatedStoreProductIds) {
          const actionType = FETCH_PRODUCTS_DETAILS;
          const constName = Object.keys({
            FETCH_PRODUCTS_DETAILS,
          })[0].toString();
          const localeCode = yield* select(
            (state) =>
              state.stores.storeInfo[storeId]?.storeDefaults.localeCode,
          );
          const headersObj = {
            "x-locale-code": localeCode || "",
            "x-currency-code": "USD",
            "x-store-id": storeId,
          };
          const endpoint = acquireEndpoint(
            constName,
            commaSeperatedStoreProductIds,
          );
          yield* call(callApi, actionType, getData, null, endpoint, headersObj);
        }
      }
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

export function* watchLoadProductAlertsList() {
  yield* takeEvery(FETCH_PRODUCT_ALERTS_LIST.REQUEST, getProductAlertsList);
}
