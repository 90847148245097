import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppState from "../../store/AppState";
import {
  selectProductFetched,
  selectProducts,
} from "../../store/product/ProductSelectors";
import {
  selectIsModalOpen,
  selectModalVariable,
} from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import {
  componentClasses,
  StyledBox,
  StyledDiv,
  StyledTypography,
} from "./SubstitutionManagementModalStyles";
import {
  selectChildCategories,
  selectCurrentCategory,
  selectTopCategories,
} from "../../store/category/CategorySelectors";
import { VARIATION_GROUP } from "../../utils/Constants";
import CustomDialog from "../common/DialogContainer";
import ButtonComponent from "../common/ButtonComponent";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { getSimilarProductsList } from "store/ai-cluster/AiClusterAction";
import {
  selectIsSimilarProductsFetched,
  selectSimilarProducts,
} from "store/ai-cluster/AiClusterSelectors";
import { selectProductListEditSequence } from "store/product-list/ProductListSelectors";
import {
  updateProductListInState,
  updateProductSequenceList,
} from "store/product-list/ProductListActions";
import { swapItemsInArray } from "utils/ProductUtil";
import SubstitutionManagementProductItem from "./SubstitutionManagementProductItem";
import { resetAllProducAnalyticsView } from "store/product/ProductActions";
import { DeleteProductByCategories } from "store/remove-product-categories/RemoveProductCategoriesActions";
export const SubstitutionManagementModalId = "SubsitutionManagementModal";

interface Props {
  categoryId: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    rightSection: {
      position: "sticky",
      top: 0,
      marginLeft: "auto",
      minWidth: "150px",
      width: "auto",
      height: "74vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
      overflowY: "auto",
      right: 0,
    },
    divActionBtn: {
      display: "flex",
      gap: 17,
    },
    divNewActionBtn: { display: "flex" },
    overlay: {
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
  }),
);
const SubstitutionManagement: React.FC<Props> = (props) => {
  let filteredProductsArray;
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyle();
  const [isAnalyticsDisplayed, setIsAnalyticsDisplayed] =
    useState<boolean>(false);
  const [selectedTile, setSelectedTile] = useState<string | null>(null);
  const fetchAllProducts = useSelector(selectProducts);
  const categoryId = useSelector(selectCurrentCategory);
  const editSequenceProducts = useSelector(selectProductListEditSequence);
  const similarProductsList = useSelector(selectSimilarProducts);
  const childCategories = useSelector(selectChildCategories);
  const topCategories = useSelector(selectTopCategories);
  const productId = useSelector((state: AppState) =>
    selectModalVariable(state, SubstitutionManagementModalId, "productId"),
  );
  const fetchedProduct = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );
  const isFetchingSimilarProductsList = useSelector(
    selectIsSimilarProductsFetched,
  );
  const similarProductIds = similarProductsList.map((p) => p.productId);
  const editSequenceProductIds = editSequenceProducts.map((p) => p.productId);

  if (similarProductsList.length) {
    filteredProductsArray = Object.keys(fetchAllProducts)
      .filter(
        (key) =>
          similarProductIds?.includes(key) &&
          fetchAllProducts[key]?.isPublished &&
          fetchAllProducts[key]?.stock !== 0,
      )
      .map((key) => fetchAllProducts[key]);
  }

  const subsitutionManagementProduct = useMemo(() => {
    if (fetchedProduct?.productId) {
      return {
        code: fetchedProduct.code,
        name: fetchedProduct.name,
        thumbnail: fetchedProduct.thumbnail,
        isPublished: fetchedProduct.isPublished,
        storeSpecific:
          fetchedProduct?.storeSpecific &&
          Object.entries(fetchedProduct.storeSpecific).map((e) => {
            return { storeId: e[0], isPublished: e[1].isPublished };
          }),
        translations: fetchedProduct?.translations,
        stock: fetchedProduct.stock,
        baseProductId:
          fetchedProduct?.typeCode === VARIATION_GROUP
            ? fetchedProduct.productId
            : fetchedProduct.variationBaseId,
        price: fetchedProduct.highPrice,
        colors: fetchedProduct.colors,
        categorySpecific: fetchedProduct.categorySpecific,
        hasSequenceableColors: fetchedProduct.hasSequenceableColors,
        typeCode: fetchedProduct.typeCode,
      };
    }
  }, [fetchedProduct]);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, SubstitutionManagementModalId),
  );

  const products: string[] = [fetchedProduct?.code ?? ""];
  const tcFilter = topCategories.filter((e) =>
    categoryId.includes(e.categoryId),
  );
  const ccFilter = Object.entries(childCategories)
    .map((e) => {
      const search = e[1].childCategories.find((el) =>
        categoryId.includes(el.categoryId),
      );
      if (search) {
        return search.categoryId;
      }
      return null;
    })
    .filter((e) => e !== null);
  const categories: string[] = [categoryId];

  const handleSubstitute = () => {
    if (selectedTile && fetchedProduct) {
      handleClose();
      dispatch(
        DeleteProductByCategories({
          productIds: products,
          categoryIds: categories,
          childrenCategories: ccFilter,
          topCategories: tcFilter,
          isSubstituteProduct: false,
        }),
      );
      const productIdsArray = swapItemsInArray(
        editSequenceProductIds,
        selectedTile,
        fetchedProduct.code,
      );
      const payload = {
        categoryId: categoryId,
        productIds: productIdsArray,
      };
      dispatch(updateProductSequenceList(payload));
      const productIdList = productIdsArray.map((id) => ({ productId: id }));
      const productPayload = {
        products: productIdList,
        isSaveAction: true,
      };
      dispatch(updateProductListInState(productPayload));
    }
  };

  const handleTileSelection = (productId: string | null) => {
    setSelectedTile(productId);
  };

  const handleClose = () => {
    dispatch(setModalState(SubstitutionManagementModalId, false));
    dispatch(resetAllProducAnalyticsView());
    setSelectedTile(null);
    setIsAnalyticsDisplayed(false);
  };

  useEffect(() => {
    if (subsitutionManagementProduct && subsitutionManagementProduct?.code) {
      dispatch(getSimilarProductsList(subsitutionManagementProduct.code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isModalOpen]);

  const rightSection = () => {
    return (
      <>
        <div className={classes.divActionBtn}>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => handleClose()}
          >
            {intl.formatMessage({
              id: "general.close",
              defaultMessage: "Close",
            })}
          </ButtonComponent>
          <ButtonComponent
            color="primary"
            variant="contained"
            onClick={() => handleSubstitute()}
            disabled={!selectedTile}
          >
            {intl.formatMessage({
              id: "general.substitute",
              defaultMessage: "Substitute",
            })}
          </ButtonComponent>
        </div>
      </>
    );
  };

  const SubsitutionMgmtModalTitle = () => {
    return (
      <Typography variant="inherit">
        Substitutions - {fetchedProduct?.name ?? ""}{" "}
        {`(${fetchedProduct?.code})`}
      </Typography>
    );
  };

  const subsitutionMgmtModalContent = () => {
    return (
      <>
        <StyledDiv className={componentClasses.paper}>
          <StyledDiv className={componentClasses.leftSection}>
            <StyledTypography className={componentClasses.baseProduct}>
              {intl.formatMessage({
                id: "substitutionManagement.baseProductTitle",
                defaultMessage: "Product",
              })}
            </StyledTypography>
            {subsitutionManagementProduct && (
              <SubstitutionManagementProductItem
                product={subsitutionManagementProduct}
                code={subsitutionManagementProduct?.code}
                isColumn={false}
              />
            )}
          </StyledDiv>
          <StyledDiv>
            <StyledDiv className={componentClasses.selectProductToSubstitute}>
              <StyledTypography className={componentClasses.substituteProducts}>
                {intl.formatMessage({
                  id: "substitutionManagement.substituteProducts",
                  defaultMessage: "Select a product substitute",
                })}
              </StyledTypography>
            </StyledDiv>
            <StyledDiv className={componentClasses.mainContent}>
              <StyledBox className={componentClasses.mainSection}>
                {isFetchingSimilarProductsList ? (
                  <StyledDiv className={componentClasses.progress}>
                    <CircularProgress style={{ margin: "auto" }} size="40px" />
                  </StyledDiv>
                ) : similarProductsList &&
                  filteredProductsArray?.length &&
                  similarProductsList.length > 0 ? (
                  filteredProductsArray?.map((product, productIndex) => (
                    <SubstitutionManagementProductItem
                      product={product}
                      code={product && product?.code}
                      price={product?.highPrice}
                      isColumn={true}
                      index={productIndex}
                      key={productIndex.toString()}
                      onTileClick={handleTileSelection}
                      isSelected={selectedTile === product.code}
                      isAnalyticsDisplayed={isAnalyticsDisplayed}
                      setIsAnalyticsDisplayed={setIsAnalyticsDisplayed}
                    />
                  ))
                ) : (
                  <StyledBox className={componentClasses.noSimilarProducts}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      {intl.formatMessage({
                        id: "substitutionManagement.noSimilarProductsFoundText",
                        defaultMessage: "No similar products found",
                      })}
                    </Typography>
                  </StyledBox>
                )}
              </StyledBox>
            </StyledDiv>
          </StyledDiv>
          <div className={classes.rightSection}>{rightSection()}</div>
        </StyledDiv>
      </>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={SubsitutionMgmtModalTitle()}
      fullWidth
      maxWidth={"lg"}
      onClick={(e) => e.preventDefault()}
    >
      {subsitutionMgmtModalContent()}
    </CustomDialog>
  );
};

export default React.memo(SubstitutionManagement);
