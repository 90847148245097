import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import CustomDialog from "../../common/DialogContainer";
import ButtonComponent from "components/common/ButtonComponent";
import AppState from "../../../store/AppState";
import { selectIsModalOpen } from "../../../store/modal/ModalSelectors";
import { setModalState } from "../../../store/modal/ModalActions";
import BadgeManagementList from "./BadgeManagementList";
import { styled } from "@mui/material/styles";

export const classes = {
  divActionBtn: "divActionBtn",
};

export const StyledDiv = styled("div")((theme) => ({
  [`&.${classes.divActionBtn}`]: {
    width: "100%",
    textAlign: "end",
    margin: "0px 20px 20px 20px",
  },
}));

function BadgeManagementModal() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "BadgeManagementModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("BadgeManagementModal", false));
  };

  const getBadgeModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "badgeManagement.title",
          defaultMessage: "Badge Management",
        })}
      </Typography>
    );
  };

  const getBadgeModalActions = () => {
    return (
      <StyledDiv className={classes.divActionBtn}>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: "itemManagementModal.closeActionLabel",
            defaultMessage: "Close",
          })}
        </ButtonComponent>
      </StyledDiv>
    );
  };

  return (
    <CustomDialog
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      title={getBadgeModalTitle()}
      actions={getBadgeModalActions()}
    >
      <BadgeManagementList />
    </CustomDialog>
  );
}

export default BadgeManagementModal;
