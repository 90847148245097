import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectProductAlertsListFetched } from "../../../store/product-alerts-modal/ProductAlertsListSelectors";
import { useMemo } from "react";
import {
  ProductStatus,
  SKU,
} from "../../../store/product-alerts-modal/ProductAlertsListType";
import { selectStoreList } from "store/store-list/StoreListSelectors";
import { CSVLink } from "react-csv";
import { styles } from "./ProductAlertsListContainerStyles";

function ProductAlertsList() {
  const intl = useIntl();
  const storesList = useSelector(selectStoreList);
  const productAlertsData = useSelector(selectProductAlertsListFetched);
  const { headerStyle, exportLinkStyle } = styles();
  const rowData = useMemo(() => {
    const data = productAlertsData.length ? productAlertsData[0].SKUs : [];
    return data?.map((SKU) => {
      return {
        ...SKU,
        storeId:
          storesList?.find((store) => store.storeId === SKU.storeId)?.code ??
          SKU.storeId,
      };
    });
  }, [productAlertsData, storesList]);

  const jobStartDate = useMemo(() => {
    return productAlertsData.length ? productAlertsData[0].jobStartTime : null;
  }, [productAlertsData]);

  const columns: GridColDef[] = [
    {
      field: "storeId",
      headerName: intl.formatMessage({
        id: "productAlertsModalColumn.store",
        defaultMessage: "Store",
      }),
      width: 150,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "productName",
      headerName: intl.formatMessage({
        id: "productAlertsModalColumn.product",
        defaultMessage: "Product",
      }),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "SKU",
      headerName: intl.formatMessage({
        id: "productAlertsModalColumn.sku",
        defaultMessage: "SKU",
      }),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: intl.formatMessage({
        id: "productAlertsModalColumn.condition",
        defaultMessage: "Condition",
      }),
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return ProductStatus[params.value];
      },
    },
    {
      field: "currentStock",
      headerName: intl.formatMessage({
        id: "productAlertsModalColumn.stock",
        defaultMessage: "Stock",
      }),
      width: 100,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const parsedRowData = useMemo(() => {
    return rowData?.map((row) => ({
      ...row,
      status: ProductStatus[row.status],
    }));
  }, [rowData]);

  return rowData.length ? (
    <>
      <div style={headerStyle}>
        <div>Date: {new Date(jobStartDate as string).toLocaleDateString()}</div>
        <CSVLink
          style={exportLinkStyle}
          data={parsedRowData}
          filename={"sm4-product-alerts.csv"}
        >
          {intl.formatMessage({
            id: "productAlertsModal.export",
            defaultMessage: "EXPORT",
          })}
        </CSVLink>
      </div>
      <DataGrid
        getRowId={(row: SKU) => row.productId + "#" + row.SKU}
        rows={rowData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            background: "linear-gradient(#fff, #efefef)",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            textTransform: "uppercase",
            fontWeight: "bold",
          },
        }}
      />
    </>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      {intl.formatMessage({
        id: "itemManagementModal.NoData",
        defaultMessage: "No records found",
      })}
    </div>
  );
}

export default ProductAlertsList;
