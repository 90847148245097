export class StyleExtractor {
  private styleCache: Set<string>;
  private initialized: boolean;
  private observer: MutationObserver;
  private subscriberCallback: (styles: string[]) => void;

  constructor(subscriberCallback: (styles: string[]) => void) {
    this.styleCache = new Set<string>();
    this.initialized = false;
    this.subscriberCallback = subscriberCallback;

    // Initialize MutationObserver
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          this.extractNewStyles();
        }
      });
    });
  }

  private extractStaticStyles(): void {
    // Extract styles from <style> tags
    document.querySelectorAll("style").forEach((styleTag) => {
      if (styleTag.innerHTML) {
        this.styleCache.add(styleTag.innerHTML);
      }
    });

    // Extract styles from dynamically injected stylesheets
    const dynamicStyleSheets = Array.from(document.styleSheets).filter(
      (sheet) => !sheet.href,
    );

    dynamicStyleSheets.forEach((sheet) => {
      const cssRules = (sheet as CSSStyleSheet).cssRules;
      for (let i = 0; i < cssRules.length; i++) {
        this.styleCache.add(cssRules[i].cssText);
      }
    });
  }

  private async fetchExternalStyles(): Promise<void> {
    const fetchPromises = Array.from(
      document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]'),
    ).map((link) => {
      return fetch(link.href)
        .then((response) => response.text())
        .then((css) => {
          this.styleCache.add(css);
        })
        .catch((error) => {
          console.error("Error fetching external stylesheet:", error);
        });
    });

    await Promise.all(fetchPromises);
  }

  private extractNewStyles(): void {
    let newStyles: string[] = [];

    // Extract styles from dynamically injected stylesheets if not cached
    const dynamicStyleSheets = Array.from(document.styleSheets).filter(
      (sheet) => !sheet.href,
    );

    dynamicStyleSheets.forEach((sheet) => {
      const cssRules = (sheet as CSSStyleSheet).cssRules;
      for (let i = 0; i < cssRules.length; i++) {
        if (!this.styleCache.has(cssRules[i].cssText)) {
          this.styleCache.add(cssRules[i].cssText);
          newStyles.push(cssRules[i].cssText);
        }
      }
    });

    if (newStyles.length > 0) {
      this.notifySubscriber(newStyles);
    }
  }

  private notifySubscriber(newStyles: string[]): void {
    this.subscriberCallback(newStyles);
  }

  public async extractStyles(): Promise<string[]> {
    if (!this.initialized) {
      this.extractStaticStyles();
      await this.fetchExternalStyles();
      this.initialized = true;

      // Start observing the document for new styles
      this.observer.observe(document.documentElement, {
        attributes: true,
        childList: true,
        subtree: true,
        attributeFilter: ["style"],
      });

      // Notify subscriber with initial styles (if needed)
      // this.notifySubscriber(Array.from(this.styleCache)); // Comment this line if initial styles shouldn't be sent
    } else {
      this.extractNewStyles();
    }

    // Convert the style cache to an array and return it
    return Array.from(this.styleCache);
  }
}
