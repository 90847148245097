import { AdapterInfo, RESET_ADAPTER_DETAILS } from "./AdapterConfigTypes";
import { FETCH_ADAPTER_CONFIG } from "./AdapterConfigTypes";

export interface AdapterConfigState {
  AdapterInfo: AdapterInfo;
}

const initialState: AdapterConfigState = {
  AdapterInfo: {
    adapterId: "",
    capabilities: [],
    operations: [],
    props: {
      PRODUCT_DETAILS_MAX_WORKERS: undefined,
      PRODUCT_DETAILS_PAGESIZE: undefined,
    },
  },
};

export const AdapterConfigReducer = (
  state: AdapterConfigState = initialState,
  action,
): AdapterConfigState => {
  const data = action.payload;
  switch (action.type) {
    case FETCH_ADAPTER_CONFIG.SUCCESS:
      return {
        ...state,
        AdapterInfo: data,
      };
    case RESET_ADAPTER_DETAILS:
      return {
        ...state,
        AdapterInfo: {
          adapterId: "",
          capabilities: [],
          operations: [],
          props: {
            PRODUCT_DETAILS_MAX_WORKERS: undefined,
            PRODUCT_DETAILS_PAGESIZE: undefined,
          },
        },
      };
    default:
      return state;
  }
};
