/**
 * Action types
 */
export const LOAD_CATEGORIES_BY_PRODUCT_IDS = "LOAD_CATEGORIES_BY_PRODUCT_IDS";
export const RESET_PRODUCT_REMOVE_STATE = "RESET_PRODUCT_REMOVE_STATE";
export const FETCH_CATEGORIES_BY_PRODUCT_IDS = {
  REQUEST: "FETCH_CATEGORIES_BY_PRODUCT_IDS_REQUEST",
  SUCCESS: "FETCH_CATEGORIES_BY_PRODUCT_IDS_SUCCESS",
  FAILURE: "FETCH_CATEGORIES_BY_PRODUCT_IDS_FAILURE",
};

export type ProductActionTypes = LoadCategoriesByProductIdsResponseAction;

export interface LoadCategoriesByProductIdsAction {
  type: typeof LOAD_CATEGORIES_BY_PRODUCT_IDS;
  payload: LoadCategoriesByProductIdsPayload;
}
export interface LoadCategoriesByProductIdsResponseAction {
  payload: LoadCategoryByProductIdResponsePayload;
  type: typeof FETCH_CATEGORIES_BY_PRODUCT_IDS.SUCCESS;
}

export interface LoadCategoriesByProductIdsPayload {
  storeId?: string;
  catalogId?: string;
  productIds: string[];
  localeCode?: string;
}
export interface CategoryType {
  categoryId: string;
  code: string;
  typeCode: string;
  name: string;
  isPublished: boolean;
}
export interface ProductCategoryType {
  productId: string;
  categories: CategoryType[];
}

export interface LoadCategoryByProductIdResponsePayload {
  products: ProductCategoryType[];
}

export const DELETE_PRODUCTS_BY_CATEGORIES_ID =
  "DELETE_PRODUCTS_BY_CATEGORIES_ID";
export const RESET_PRODUCT_DELETED = "RESET_PRODUCT_DELETED";
export const DELETE_PRODUCT_BY_CATEGORIES = {
  REQUEST: "DELETE_PRODUCT_BY_CATEGORIES_REQUEST",
  SUCCESS: "DELETE_PRODUCT_BY_CATEGORIES_SUCCESS",
  FAILURE: "DELETE_PRODUCT_BY_CATEGORIES_FAILURE",
  DONE: "DELETE_PRODUCT_BY_CATEGORIES_DONE",
};

export type CategoryActionTypes = DeleteProductByCategoriesIdResponseAction;

export interface DeleteProductByCategoriesPayload {
  categoryIds: string[];
  productIds: string[];
  childrenCategories?: (string | null)[];
  topCategories?: {
    categoryId: string;
    childCategoryCount: number;
    productCount: number;
    translations: any;
    name: string;
    code: string;
    isPublished: boolean;
  }[];
  isSubstituteProduct?: boolean;
}

export interface DeleteProductByCategoriesAction {
  type: typeof DELETE_PRODUCTS_BY_CATEGORIES_ID;
  payload: DeleteProductByCategoriesPayload;
}

export interface DeleteProductByCategoriesIdResponseAction {
  payload: DeleteProductByCategoriesIdResponsePayload;
  type: typeof DELETE_PRODUCT_BY_CATEGORIES.SUCCESS;
}

export interface DeleteProductByCategoriesIdResponsePayload {
  products: ProductCategoryType[];
}

export interface DeleteProductByCategoriesIdSuccessAndErrorResponses {
  success: DeleteProductByCategoriesApiResult[];
  errors: DeleteProductByCategoriesApiResult[];
}

export interface DeleteProductByCategoriesApiResult {
  type: string;
  productId: string;
  categoryId: string;
  errorMessage?: string;
}
