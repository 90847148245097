import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import ProductDetail from "../ProductDetail";
import { Product } from "../../store/product/ProductTypes";
import ProductSwatches from "../ProductSwatches";
import AppState from "../../store/AppState";
// import Chip from '@mui/material/Chip';
import {
  makeSelectProductSwatches,
  selectProductPrice,
  selectProductLeadingColor,
  selectedProductSwatches,
  selectIsProductDetailRefereshing,
  selectProductAnalyticsData,
  selectProductLeadingImage,
} from "../../store/product/ProductSelectors";
import {
  isRefreshingAllProducts,
  selectEditSequenceList,
  selectProductListEditSequence,
} from "../../store/product-list/ProductListSelectors";
import ProductInventoryTable from "../product-inventory-tile/ProductInventoryTable";
import ProductAttributeSelection from "../product-attribute-tile/ProductAttributeSelection";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import AnalyticsOverlay from "../overlays/AnalyticsOverlay";
import PinnedProductOverlay from "../overlays/PinnedProductOverlay";
import ProductCategoryOverlay from "../overlays/ProductCategoryOverlay";
import {
  selectConfigValue,
  selectProductImagePrefix,
} from "../../store/app-config/AppConfigSelectors";
import {
  selectCurrentLocale,
  selectCurrentStoreId,
} from "../../store/store-list/StoreListSelectors";
import { PLATFORM_DEFAULTS } from "../../utils/DefaultConfigs";
import {
  calculateBadges,
  getProductLeadingColorImageSFCC,
  getProductLeadingImageOnInitialLoad,
  isAbsolutePath,
} from "../../utils/ProductUtil";
import {
  resetAllProducAnalyticsView,
  updatedSelectedSwatches,
} from "../../store/product/ProductActions";
import { OVERLAY_TABS } from "../../utils/Constants";
import Rating from "@mui/material/Rating";
import { selectLeadingColorImageForProductInCategory } from "../../store/color-management/ColorManagementSelectors";
import { selectLeadingImageUrlForProductInCategory } from "../../store/leading-image/LeadingImageSelectors";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AdapterInfoObject } from "../../utils/AppConfigUtils";
import { selectAdapterInfo } from "../../store/adapter-config/AdapterConfigSelectors";
import { ViewContext } from "../../context/view-context";
import {
  useStyles,
  StyledProductTileContent,
  StyledLoadingContainer,
  ScrollableContainer,
  StyledContainer,
  StyledCircularDiv,
  StyledSwatchDiv,
  StyledLoadingScoreContainer,
} from "./ProductTileContentStyles";
import swatchIcon from "../../assets/color-swatch-icon.svg";
import Popover from "@mui/material/Popover";
import { ColorData } from "store/color-management/ColorManagementTypes";
import TooltipComponent from "components/common/ToolTip";
import {
  selectClusterRefreshResp,
  selectIsClusterResponseFetched,
  selectIsProductRequestListFetched,
} from "store/ai-cluster/AiClusterSelectors";
import { SubstitutionIcon } from "assets";
import { BoostIcon, BuriedIcon, SMAIIcon } from "assets";
import { SubstitutionManagementModalId } from "components/substitution-mangement-modal/SubstitutionManagement";
import { selectIsModalOpen } from "store/modal/ModalSelectors";
import {
  productBadgeId,
  productBadgeList,
} from "store/product-badge/ProductSelectors";
import { CDN_WIDGET_URL, removeDotFromStart } from "utils/WidgetUtil";
import {
  updateProductListInState,
  updateProductSequenceList,
} from "store/product-list/ProductListActions";
import { setModalState } from "store/modal/ModalActions";

interface Props {
  product: Product;
  rating?: number;
  reviewCount?: number;
  score?: number;
  rank?: string;
  index: number;
  sequence?: number;
  productView: boolean;
  isDragging: boolean;
  isRecentlyAdded?: boolean;
}

const ProductTileContent: React.FC<Props> = (props) => {
  const intl = useIntl();
  const updatedProducts = useSelector(selectEditSequenceList);
  const {
    index,
    rating,
    reviewCount,
    score,
    rank,
    product: {
      typeCode,
      code,
      productId,
      name,
      productScore,
      stock,
      colors,
      hasColors,
    },
    sequence,
    productView,
    isDragging,
    isRecentlyAdded,
  } = props;
  const currentProductFromUpdatedState = updatedProducts.find(
    (productData) => productData.productId === productId,
  );
  const [selectedThumbnailImage, setSelectedThumbnailImage] = useState<
    string | undefined
  >("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const localeId = useSelector(selectCurrentLocale) || "";
  const currentCategoryId = useSelector(selectCurrentCategory) || "";
  const editSequenceProducts = useSelector(selectProductListEditSequence);
  const storeId = useSelector(selectCurrentStoreId) || "";
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[ecomPlatformType];
  const [colorName, setColorName] = useState<String>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const productImagePrefix = useSelector((state: AppState) =>
    selectProductImagePrefix(state, storeId),
  );
  const open = Boolean(anchorEl);
  const publishedColors = colors?.filter(({ isPublished }) => isPublished);
  const displaySequence =
    useSelector((state: AppState) =>
      selectConfigValue(state, "displaySequences"),
    ) === "true";
  const colorSequenceMode = useSelector((state: AppState) =>
    selectConfigValue(state, "colorSequenceMode"),
  );
  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );
  const attrValSequenceLocaleSpecific =
    useSelector((state: AppState) =>
      selectConfigValue(state, "attrValSequenceLocaleSpecific"),
    ) === "true";
  const leadingColorEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "leadingColorEnabled"),
    ) === "true";
  const storeAndAccountInventory = useSelector((state: AppState) =>
    selectConfigValue(state, "inventoryThreshold"),
  );
  const inventoryThreshold = parseInt(
    storeAndAccountInventory !== ""
      ? storeAndAccountInventory
      : (platformDefaults?.inventoryThreshold ?? ""),
  );

  const substituteSimilarProduct = stock === 0 || stock <= inventoryThreshold;
  const isClusterResponseFetched = useSelector(selectIsClusterResponseFetched);

  let type = typeCode === "ProductBean" ? "Product" : typeCode;
  const view = useContext(ViewContext);
  const columns = view.styles.column;
  const changeStyles =
    columns === 6 ? { fontSize: "0.75rem" } : { fontSize: "inherit" };
  const productTypeStyles =
    columns === 6 || columns === 5
      ? {
          display: "block",
          fontSize: "0.75rem",
        }
      : { fontSize: "inherit" };
  const selectProductSwatches = useMemo(makeSelectProductSwatches, []);
  const swatches = useSelector((state: AppState) =>
    selectProductSwatches(
      state,
      productId,
      colorSequenceMode,
      attrValSequenceLocaleSpecific,
      localeId,
      defaultLocaleId,
    ),
  );
  const productPrice = useSelector((state: AppState) =>
    selectProductPrice(state, productId),
  );
  const leadingColors = useSelector((state: AppState) =>
    selectProductLeadingColor(state, productId),
  );

  const selectedOverlay = useSelector(selectOverlay);
  const selectedSwatches = useSelector(selectedProductSwatches);
  const isProductDetailRefreshing = useSelector((state: AppState) =>
    selectIsProductDetailRefereshing(state, productId),
  );
  const showProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productId),
  )?.showAnalytics;
  const loadSingleProductAnalytics = useSelector((state: AppState) =>
    selectProductAnalyticsData(state, productId),
  )?.loadAnalytics;

  const productLeadingImage = useSelector((state: AppState) =>
    selectProductLeadingImage(state, productId, currentCategoryId),
  );
  const isRefreshingEveryProduct = useSelector(isRefreshingAllProducts);
  const isBoostBuryEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableBoostBury"),
  );
  const fetchBoostBuryActionEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableBoostBuryAction"),
  );
  const isBoostBuryActionEnabled = fetchBoostBuryActionEnabled === "true";
  const selectedSwatch = selectedSwatches[productId]?.selectedSwatch ?? "";
  const showBoostBury =
    isBoostBuryEnabled === "true" && selectedOverlay === OVERLAY_TABS.SMAI;
  const isSMAIOverlay = selectedOverlay === OVERLAY_TABS.SMAI;
  const isSubstitutionModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, SubstitutionManagementModalId),
  );
  const showSubstitutionLoading =
    substituteSimilarProduct &&
    isBoostBuryEnabled !== "true" &&
    selectedOverlay === OVERLAY_TABS.SMAI &&
    !isClusterResponseFetched;

  let price = productPrice ? productPrice.price.toString() : "N/A";
  if (productPrice && productPrice.lowPrice < productPrice.highPrice) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    price = productPrice.lowPrice + "-" + productPrice.highPrice;
  }
  const productData = props.product;
  const updatedBaseProductLeadingColorImage = useSelector((state: AppState) =>
    selectLeadingColorImageForProductInCategory(
      state,
      productId,
      currentCategoryId,
    ),
  );

  const isSubstitutionEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableSubstitution"),
  );
  const productLeadingImageOnInitialLoad = useMemo(() => {
    const leadingImage = getProductLeadingImageOnInitialLoad(
      productData,
      currentCategoryId,
    );
    return leadingImage;
  }, [productData, currentCategoryId]);

  const isAiOverlay = selectedOverlay === OVERLAY_TABS.SMAI;
  const isLowstock = stock === 0 || stock <= inventoryThreshold;

  const showSubstituion =
    isClusterResponseFetched &&
    isAiOverlay &&
    isLowstock &&
    isSubstitutionEnabled === "true";

  const productLeadingColorImage = useMemo(() => {
    if (productData?.categorySpecific) {
      return productData?.categorySpecific?.length > 0
        ? getProductLeadingColorImageSFCC(productData, currentCategoryId)
        : "";
    } else {
      return updatedBaseProductLeadingColorImage;
    }
  }, [productData, currentCategoryId, updatedBaseProductLeadingColorImage]);

  const inventory =
    stock >= 0
      ? intl.formatMessage({
          id: "productTileContent.inventoryText",
          defaultMessage: "INV",
        }) +
        " : " +
        stock
      : "";
  let colorIndex = -1;
  if (
    leadingColorEnabled &&
    leadingColors &&
    leadingColors.length > 0 &&
    swatches
  ) {
    colorIndex = swatches.findIndex(
      (swatch) => swatch.attributeValueId === leadingColors[0].attributeValueId,
    );
  }
  if (colorIndex < 0 && swatches) {
    colorIndex = swatches.findIndex((color) => color.isPublished);
  }

  const id = open ? productId : undefined;
  const leadingImageUrl =
    useSelector((state: AppState) =>
      selectLeadingImageUrlForProductInCategory(
        state,
        productId,
        currentCategoryId,
      ),
    ) ?? "";

  const productImage = useMemo(() => {
    const image = leadingImageUrl
      ? leadingImageUrl
      : productLeadingImage
        ? productLeadingImage
        : productLeadingImageOnInitialLoad
          ? productLeadingImageOnInitialLoad
          : productLeadingColorImage
            ? productLeadingColorImage
            : colors?.find((color) => color.isPublished)?.thumbnail
              ? colors.find((color) => color.isPublished)?.thumbnail
              : productData.thumbnail;
    return image;
  }, [
    productData,
    leadingImageUrl,
    productLeadingImage,
    productLeadingImageOnInitialLoad,
    productLeadingColorImage,
    colors,
  ]);

  const getColorSwatchName = useMemo(() => {
    // Check if the color name exists in publishedColors
    const colorName = publishedColors?.find(
      (color) => color.thumbnail === productImage,
    )?.name;

    if (colorName) return colorName;

    // Function to extract color name from variation attributes
    const getColorFromAttributes = (variationAttributes) => {
      for (const attr of variationAttributes) {
        for (const attrValue of attr.values || []) {
          const matchingColor = publishedColors?.find(
            (color) => color.colorId === attrValue.value,
          )?.name;
          if (matchingColor) return matchingColor;
        }
      }
      return undefined;
    };

    // Process image groups if productData and imageGroups exist
    if (productData && productData.imageGroups) {
      for (const imageGroup of productData.imageGroups) {
        if (
          imageGroup?.images?.some((image) => image.thumbnail === productImage)
        ) {
          if (
            imageGroup.variationAttributes &&
            Array.isArray(imageGroup.variationAttributes) &&
            imageGroup.variationAttributes.length > 0
          ) {
            const imageColorName = getColorFromAttributes(
              imageGroup.variationAttributes,
            );
            if (imageColorName) return imageColorName;
          }
        }
      }
    }

    return undefined;
  }, [publishedColors, productData, productImage]);

  const isRatingVisible = rating !== -1 && reviewCount !== -1;
  const clusterRefreshed = useSelector(selectClusterRefreshResp);
  const isClusterFetched = useSelector(selectIsProductRequestListFetched);
  const isRefreshed =
    clusterRefreshed.length &&
    clusterRefreshed[0]?.response?.statusCode === 200;

  const positionOrSequenceDisplay = displaySequence
    ? intl.formatMessage({
        id: "productTileContent.sequenceText",
        defaultMessage: "Seq",
      }) +
      " : " +
      sequence
    : intl.formatMessage({
        id: "productTileContent.positionText",
        defaultMessage: "Position",
      }) +
      " : " +
      (sequence && currentProductFromUpdatedState?.isInitialPositionAvailable
        ? sequence
        : intl.formatMessage({
            id: "productTileContent.notSetText",
            defaultMessage: "Not set",
          }));

  const swatchClickHandler = useCallback(
    (image: string, attrValId: string) => {
      const swatchObj = {
        selectedSwatch: attrValId,
        productImage: image,
      };

      dispatch(
        updatedSelectedSwatches({
          swatch: swatchObj,
          productId,
        }),
      );
    },
    [dispatch, productId],
  );

  const openSubsitutionManagement = () => {
    dispatch(resetAllProducAnalyticsView());
    dispatch(setModalState(SubstitutionManagementModalId, true, { productId }));
  };

  const finalProductScore = Array.isArray(productScore)
    ? productScore.reduce((partialSum, a) => partialSum + a, 0)
    : productScore;

  const handleColorSwatchName = useCallback((event, swatchImage, colorName) => {
    event.stopPropagation();
    setSelectedThumbnailImage(swatchImage);
    setColorName(colorName);
  }, []);

  const handleMultipleColorSwatches = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const productIdsArray = editSequenceProducts.map((p) => p.productId);
  const handleBoostBury = (event, productId, isBury = false) => {
    event.stopPropagation();
    if (
      (!isBury && productIdsArray[0] === productId) ||
      (isBury && productIdsArray[productIdsArray.length - 1] === productId)
    ) {
      return;
    }
    const updatedProductArray = productIdsArray.filter(
      (id) => id !== productId,
    );
    if (isBury) {
      updatedProductArray.push(productId);
    } else {
      updatedProductArray.unshift(productId);
    }
    const payload = {
      categoryId: currentCategoryId,
      productIds: updatedProductArray,
    };
    dispatch(updateProductSequenceList(payload));
    const productIdList = updatedProductArray.map((id) => ({ productId: id }));
    const productPayload = {
      products: productIdList,
      isSaveAction: true,
    };
    dispatch(updateProductListInState(productPayload));
  };

  const mainBadgesInfo = useSelector(productBadgeList);
  const queryProductBadge = useSelector(productBadgeId);
  const badgeId = calculateBadges(
    mainBadgesInfo,
    queryProductBadge.productBadgeAssignments,
    productId,
  );

  const colorSwatches = (color: ColorData) => {
    const isColorSwatchSelected = color.name === colorName;
    let borderClass;
    if (color.stock === 0) {
      borderClass = classes.redBorder(isColorSwatchSelected);
    } else if (color.stock <= inventoryThreshold) {
      borderClass = classes.yellowBorder(isColorSwatchSelected);
    } else if (isColorSwatchSelected) {
      borderClass = classes.selectedBoxStyle;
    } else {
      borderClass = classes.defaultBorder;
    }

    const swatchClass = {
      ...classes.colorSwatchImageContainer,
      ...borderClass,
    };
    const productColorSwatch = color.swatchImage || color.thumbnail;
    const swatchImage = isAbsolutePath(productColorSwatch)
      ? productColorSwatch
      : productImagePrefix + productColorSwatch;
    return (
      <Box style={classes.swatch}>
        <div
          key={color.code}
          onClick={(event) =>
            handleColorSwatchName(event, color.thumbnail, color.name)
          }
          title={color.name}
        >
          <div style={swatchClass}>
            <img
              src={swatchImage}
              alt={color.code}
              style={classes.colorSwatchImage}
            />
          </div>
        </div>
      </Box>
    );
  };

  useEffect(() => {
    const colorName = getColorSwatchName;
    if (colorName) setColorName(colorName);
  }, [getColorSwatchName]);

  const renderColorSwatches = useMemo(() => {
    if (publishedColors && publishedColors.length > 0) {
      return (
        <ScrollableContainer>
          {publishedColors.map((color, index) => {
            //Rendering color swatches upto 4 for columns 5,6 & upto 6 for remaining columns
            if (
              color.thumbnail !== "" &&
              color.swatchImage !== "" &&
              ((index < 4 && (columns === 6 || columns === 5)) ||
                (index < 6 && columns !== 6 && columns !== 5))
            ) {
              return colorSwatches(color);
            } else {
              return null;
            }
          })}
          {/*New condition to display the swatch container button on columns 2 to 4 if there are more than 6 swatch images  
          and on columns 5,6 if there are more than 4 swatch images*/}
          {((publishedColors.length > 4 && (columns === 6 || columns === 5)) ||
            publishedColors.length > 6) && (
            <>
              <div
                id={id}
                aria-describedby={id}
                style={classes.swatchButton}
                onClick={(event) => handleMultipleColorSwatches(event)}
              >
                <img
                  src={`${CDN_WIDGET_URL}${removeDotFromStart(swatchIcon)}`}
                  alt="Swatch Icon"
                />
              </div>
              <Popover
                open={open}
                onClose={(event) => handleClose(event)}
                id={id}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                anchorEl={anchorEl}
                sx={classes.popoverStyles}
              >
                <div style={classes.swatchBox}>
                  {publishedColors.map((color, index) => {
                    //Excluding initial 4 color swatches for columns 5,6 & upto 6 for remaining columns
                    if (
                      color.thumbnail !== "" &&
                      color.swatchImage !== "" &&
                      ((index > 3 && (columns === 6 || columns === 5)) ||
                        (index > 5 && columns !== 6 && columns !== 5))
                    ) {
                      return colorSwatches(color);
                    } else {
                      return null;
                    }
                  })}
                </div>
              </Popover>
            </>
          )}
        </ScrollableContainer>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    colors,
    inventoryThreshold,
    selectedThumbnailImage,
    open,
    anchorEl,
    publishedColors,
  ]);

  // TODO: randomly generate inv for demo. need to calculate based on core sizes
  // const coreSizesInventory = productInventory ? 'Core : ' + Math.floor(productInventory.inventory * Math.random()) : '';
  return (
    <>
      {(!productView ||
        (selectedOverlay !== OVERLAY_TABS.INVENTORY_OVERLAY &&
          selectedOverlay !== OVERLAY_TABS.ATTRIBUTES_OVERLAY)) && (
        <StyledProductTileContent isSMAIOverlay={isSMAIOverlay}>
          <Box>
            <div style={classes.position}>{positionOrSequenceDisplay}</div>
            <Box>
              <TooltipComponent
                tooltipTitle={type}
                placement="bottom"
                interactive
                enterDelay={500}
              >
                <Typography
                  style={productTypeStyles}
                  sx={classes.productType}
                  variant="subHeader"
                >
                  {type}
                </Typography>
              </TooltipComponent>
            </Box>
            <Box>
              <TooltipComponent
                tooltipTitle={code}
                interactive
                enterDelay={500}
              >
                <Typography
                  style={changeStyles}
                  sx={classes.productCode}
                  variant="subtitle2"
                >{`${code}`}</Typography>
              </TooltipComponent>
            </Box>
            <Box>
              <Typography
                sx={stock === 0 ? classes.inventoryColor : {}}
                variant="subHeader"
              >
                {inventory}
              </Typography>
              {showSubstituion && (
                <IconButton
                  size="medium"
                  color="inherit"
                  title={intl.formatMessage({
                    id: "productTile.substitutionIcon",
                    defaultMessage: "Substitutions",
                  })}
                  component="div"
                  aria-label="Substitute Similar Products"
                  onClick={openSubsitutionManagement}
                  sx={classes.substitutionIcon}
                >
                  <SubstitutionIcon width={31} height={30} />
                </IconButton>
              )}
            </Box>

            {finalProductScore !== undefined ? (
              <div>
                <Typography variant="highlightedText" style={changeStyles}>
                  {intl.formatMessage({
                    id: "productDetail.productScore",
                    defaultMessage: "Product Score: ",
                  })}
                </Typography>
                <Typography variant="highlightedText" style={changeStyles}>
                  {finalProductScore.toFixed(2)}
                </Typography>
              </div>
            ) : null}
          </Box>
        </StyledProductTileContent>
      )}
      {showSubstitutionLoading || showBoostBury ? (
        showSubstitutionLoading ? (
          <StyledLoadingScoreContainer>
            Loading Substitution...
          </StyledLoadingScoreContainer>
        ) : isRefreshed && !isClusterFetched && score !== -1 ? (
          <Box sx={classes.ratingsContainer}>
            <div style={classes.scoreTextPadding}>
              <Typography variant="subHeader" sx={classes.substitutionText}>
                <SMAIIcon />
                AI rating: {`${score ? (score * 100).toFixed(2) : 0}%`}
                {rank === "boosted" ? (
                  <BoostIcon
                    style={classes.boostBuryIcon(isBoostBuryActionEnabled)}
                    onClick={(event) =>
                      isBoostBuryActionEnabled &&
                      handleBoostBury(event, productId)
                    }
                  />
                ) : rank === "buried" ? (
                  <BuriedIcon
                    style={classes.boostBuryIcon(isBoostBuryActionEnabled)}
                    onClick={(event) =>
                      isBoostBuryActionEnabled &&
                      handleBoostBury(event, productId, true)
                    }
                  />
                ) : null}
              </Typography>
            </div>
          </Box>
        ) : (
          <StyledLoadingScoreContainer>Loading...</StyledLoadingScoreContainer>
        )
      ) : null}
      {!isDragging &&
        productView &&
        selectedOverlay !== OVERLAY_TABS.INVENTORY_OVERLAY &&
        selectedOverlay !== OVERLAY_TABS.ATTRIBUTES_OVERLAY &&
        selectedOverlay !== OVERLAY_TABS.CATEGORIES_OVERLAY && (
          <PinnedProductOverlay productId={productId} index={index} />
        )}
      {!isSubstitutionModalOpen &&
        (productView || loadSingleProductAnalytics || showProductAnalytics) && (
          <AnalyticsOverlay
            productId={productId}
            showProductAnalytics={showProductAnalytics}
            loadSingleProductAnalytics={loadSingleProductAnalytics}
          />
        )}
      {productView && selectedOverlay === OVERLAY_TABS.CATEGORIES_OVERLAY && (
        <ProductCategoryOverlay productId={productId} />
      )}
      {isProductDetailRefreshing || isRefreshingEveryProduct ? (
        <StyledContainer>
          <StyledCircularDiv>
            <CircularProgress />
          </StyledCircularDiv>
        </StyledContainer>
      ) : null}

      <ProductDetail
        name={name}
        price={
          props.product.listPrice !== undefined
            ? props.product.listPrice.toString()
            : ""
        }
        priceRange={
          props.product?.lowPrice && props.product?.highPrice
            ? [props.product.lowPrice, props.product.highPrice]
            : props.product?.lowPrice
              ? [props.product.lowPrice]
              : null
        }
        type={props.product.typeCode}
        image={selectedThumbnailImage ? selectedThumbnailImage : productImage}
        isRecentlyAdded={isRecentlyAdded}
        badgeId={badgeId}
      />
      {productView &&
      selectedOverlay === OVERLAY_TABS.INVENTORY_OVERLAY &&
      typeCode !== "ProductSet" &&
      typeCode !== "ProductBundle" ? (
        <ProductInventoryTable
          productId={productId}
          swatches={swatches ? swatches : []}
        />
      ) : productView &&
        selectedOverlay === OVERLAY_TABS.ATTRIBUTES_OVERLAY &&
        typeCode !== "ProductSet" &&
        typeCode !== "ProductBundle" ? (
        <ProductAttributeSelection productId={productId} />
      ) : (
        swatches &&
        swatches.length > 0 &&
        typeCode !== "ProductSet" &&
        typeCode !== "ProductBundle" && (
          <ProductSwatches
            productId={productId}
            swatches={swatches}
            selectedSwatch={selectedSwatch}
            onSwatchClick={swatchClickHandler}
          />
        )
      )}

      {
        <StyledSwatchDiv
          style={{
            visibility:
              hasColors && selectedOverlay !== "ratings" ? "visible" : "hidden",
          }}
        >
          {publishedColors &&
            publishedColors.length > 0 &&
            colorName !== "" && (
              <Typography variant="body3">Color: {colorName}</Typography>
            )}
          {renderColorSwatches}
        </StyledSwatchDiv>
      }
      {selectedOverlay === "ratings" ? (
        isRatingVisible ? (
          <Box ml={1} sx={classes.ratingsContainer}>
            <div style={classes.ratingContainerMargin}>
              <Rating
                name="read-only"
                value={rating}
                precision={0.5}
                readOnly
              />
            </div>
            <div style={classes.ratingTextPadding}>
              <Typography variant="inherit">
                {`${rating} (${reviewCount})`}
              </Typography>
            </div>
          </Box>
        ) : (
          <StyledLoadingContainer>Loading...</StyledLoadingContainer>
        )
      ) : null}
    </>
  );
};

export default React.memo(ProductTileContent);
