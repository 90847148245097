import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useIntl } from "react-intl";

import {
  LoadTopCategoriesByCatalogId,
  updateTopCategoriesLoadingState,
  setCategoryExpandState,
  setTopCategoriesProgressState,
  // closeAllCategorySubscriptionsAndResetState,
} from "../../store/category/CategoryActions";

import AppState from "../../store/AppState";
import {
  selectAllCatalogs,
  selectCurrentCatalogId,
  selectIsCatalogsLoadedState,
} from "../../store/catalog/CatalogSelectors";
import { resetProductList } from "../../store/product-list/ProductListActions";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
  selectStoreListIds,
} from "../../store/store-list/StoreListSelectors";
import {
  setCurrentCatalogId,
  LoadAllCatalogs,
} from "../../store/catalog/CatalogActions";
import { useQuery } from "../../hooks/useQueryParams";
import {
  selectCallerAccountId,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import { resetProducts } from "../../store/product/ProductActions";
import { Skeleton, Typography } from "@mui/material";
import DropdownMenu from "../common/DropdownMenu";
import { selectAdapterInfo } from "store/adapter-config/AdapterConfigSelectors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 1),
  },
  skeletonLabel: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
}));

const CatalogSelection: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname;
  const query = useQuery();
  const catalogIdQuery = query.get("catalogId") ?? "";
  const storeIdQuery = query.get("storeId") ?? "";
  const localeCodeQuery = query.get("localeCode") ?? "";
  const localeCodeQueryStr =
    localeCodeQuery !== "" ? `&localeCode=${localeCodeQuery}` : "";

  const currentStoreId = useSelector(selectCurrentStoreId) ?? "";
  const currentCatalogId = useSelector(selectCurrentCatalogId);
  const currentLocaleId = useSelector(selectCurrentLocale) ?? "";
  const storeListIds = useSelector(selectStoreListIds);
  const isCatalogsLoaded = useSelector(selectIsCatalogsLoadedState);
  const adapterId = useSelector(selectAdapterInfo)?.adapterId;
  const graphQLStoreRelationshipEnabled =
    useSelector((state: AppState) =>
      selectConfigValue(state, "graphQLStoreRelationshipEnabled"),
    ) === "true";

  const catalogList: { name: string; id: string; code: string }[] = useSelector(
    (state: AppState) => selectAllCatalogs(state),
  );

  const dispatch = useDispatch();
  const userAccountId = useSelector(selectCallerAccountId);

  useEffect(() => {
    if (adapterId === "hcl" || adapterId === "") {
      if (currentStoreId !== "" && !graphQLStoreRelationshipEnabled)
        dispatch(LoadAllCatalogs(currentStoreId));
    } else if (!graphQLStoreRelationshipEnabled) {
      dispatch(LoadAllCatalogs(currentStoreId));
    }
  }, [dispatch, graphQLStoreRelationshipEnabled, currentStoreId, adapterId]);

  const handleCatalogChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;
    dispatch(setTopCategoriesProgressState());
    dispatch(setCurrentCatalogId(changeEvent.target.value));
    dispatch(updateTopCategoriesLoadingState());
    dispatch(setCategoryExpandState());
    dispatch(resetProductList());
    dispatch(resetProducts());
    if (changeEvent.target.value !== "") {
      dispatch(
        LoadTopCategoriesByCatalogId(
          changeEvent.target.value,
          currentLocaleId,
          currentStoreId,
        ),
      );
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}&catalogId=${changeEvent.target.value}`,
      );
    } else {
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}`,
      );
    }
  };

  useEffect(() => {
    if (
      currentStoreId !== "" &&
      catalogList.find((e) => e.id === catalogIdQuery)
    ) {
      if (currentCatalogId !== catalogIdQuery) {
        dispatch(setCurrentCatalogId(catalogIdQuery));
        dispatch(
          LoadTopCategoriesByCatalogId(
            catalogIdQuery,
            currentLocaleId,
            currentStoreId,
          ),
        );
      }
    } else {
      if (currentCatalogId !== "") {
        dispatch(setCurrentCatalogId(""));
      }
    }
  }, [
    catalogIdQuery,
    catalogList,
    currentCatalogId,
    currentLocaleId,
    currentStoreId,
    dispatch,
  ]);

  useEffect(() => {
    if (
      storeListIds.find((e) => e.storeId === storeIdQuery) &&
      catalogList.length > 0 &&
      !catalogList.find((e) => e.id === catalogIdQuery)
    ) {
      history.push(
        `${url}?accountId=${userAccountId}&storeId=${storeIdQuery}${localeCodeQueryStr}`,
      );
    }
  }, [
    storeListIds,
    catalogIdQuery,
    localeCodeQueryStr,
    catalogList,
    storeIdQuery,
    history,
    url,
    userAccountId,
  ]);

  return (
    <>
      {currentStoreId !== "" && !isCatalogsLoaded ? (
        <div className={classes.skeletonLabel}>
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={30}
            animation="wave"
          />
        </div>
      ) : currentStoreId !== "" && catalogList.length ? (
        <FormControl
          style={{ margin: "4px 8px" }}
          className={classes.formControl}
        >
          <InputLabel id="catalog-list-label">
            <Typography variant="sidebarTitle">
              {intl.formatMessage({
                id: "catalogSelection.actionLabel",
                defaultMessage: "Select a catalog",
              })}
            </Typography>
          </InputLabel>
          <DropdownMenu
            menuId="CatalogSelection"
            catalogList={catalogList}
            changeHandler={handleCatalogChange}
          />
        </FormControl>
      ) : null}
    </>
  );
};

export default React.memo(CatalogSelection);
