import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectOverlay } from "../../store/overlay/OverlaySelectors";
import { OVERLAY_TABS } from "../../utils/Constants";
import {
  selectProgressLoadingPercentage,
  selectWorkingProductSequence,
} from "../../store/product-list/ProductListSelectors";
import { fetchRefershClusterRequestAction } from "store/ai-cluster/AiClusterAction";
import { selectClusterRequestId } from "store/ai-cluster/AiClusterSelectors";
import AppState from "store/AppState";
import { selectConfigValue } from "store/app-config/AppConfigSelectors";

const SmAiOverlayLabel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const overlay = useSelector(selectOverlay);
  const productCodes = useSelector(selectWorkingProductSequence);
  const requestId = useSelector(selectClusterRequestId);
  const progressValue = useSelector(selectProgressLoadingPercentage);
  const isBoostBury = useSelector((state: AppState) =>
    selectConfigValue(state, "enableBoostBury"),
  );
  const shouldFetchClusterList = isBoostBury === "true";

  useEffect(
    () => {
      if (
        progressValue &&
        requestId &&
        overlay === OVERLAY_TABS.SMAI &&
        productCodes.length > 0
      ) {
        const payload = {
          requestId,
          shouldFetchClusterList,
        };
        dispatch(fetchRefershClusterRequestAction(payload));
      }
    },
    // eslint-disable-next-line
    [productCodes, overlay, dispatch, requestId],
  );

  return (
    <>
      {intl.formatMessage({
        id: "overlayTabs.aiLabel",
        defaultMessage: "SM AI",
      })}
    </>
  );
};

export default React.memo(SmAiOverlayLabel);
