import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "typed-redux-saga/macro";
import {
  ADD_CLUSTER_EMBEDDINGS,
  ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY,
  FETCH_CLUSTER_LIST,
  FETCH_CLUSTER_REQUEST_BY_CATEGORY,
  FETCH_SIMILAR_PRODUCTS_BY_CATEGORY,
  GET_CLUSTER_LIST,
  GET_CLUSTER_REQUEST_LIST,
  GET_SIMILAR_PRODUCTS_LIST,
} from "./AiClusterType";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { getData, postData, putData } from "../../services/ApiService";
import { selectCurrentStoreId } from "store/store-list/StoreListSelectors";
import { selectCurrentCategory } from "store/category/CategorySelectors";
import { addGlobalAlertState } from "store/global-alert/GlobalAlertActions";
import { selectClusterRequestId } from "./AiClusterSelectors";

function* getClusterList(action) {
  try {
    const actionType = FETCH_CLUSTER_LIST;
    const constName = Object.keys({
      FETCH_CLUSTER_LIST,
    })[0].toString();
    const categoryId = yield* select(selectCurrentCategory);
    const storeId = yield* select(selectCurrentStoreId);
    const endpoint = storeId
      ? acquireEndpoint(constName, categoryId, storeId)
      : "";
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (e: any) {
    console.error(e);
    yield* put({ type: FETCH_CLUSTER_LIST.FAILURE, message: e.message });
  }
}

function* addClusterEmbeddings(action) {
  try {
    yield* put({ type: ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.REQUEST });
    const actionType = ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY;
    const constName = Object.keys({
      ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY,
    })[0].toString();
    const {
      accountId,
      categoryId,
      catalogId,
      storeId,
      localeCode,
      refresh,
      currencyCode,
    } = action.payload;

    const currentCurrencyCode = currencyCode?.trim() || "USD";
    const isRefresh = refresh ? "true" : "false";

    const payload = {
      accountId: accountId,
      categoryId: categoryId,
      storeId: storeId,
      localeCode: localeCode,
      catalogId: catalogId,
      currencyCode: currentCurrencyCode,
    };

    const endpoint = acquireEndpoint(constName, isRefresh);
    const response = yield* call(
      callApi,
      actionType,
      putData,
      payload,
      endpoint,
    );
    if (
      response.type === ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.SUCCESS &&
      refresh
    ) {
      yield* put({
        type: GET_CLUSTER_REQUEST_LIST,
        payload: response.payload.requestId,
      });
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

function* fetchClusterRequestList(action) {
  try {
    yield* put({ type: FETCH_CLUSTER_REQUEST_BY_CATEGORY.REQUEST });
    const actionType = FETCH_CLUSTER_REQUEST_BY_CATEGORY;
    const constName = Object.keys({
      FETCH_CLUSTER_REQUEST_BY_CATEGORY,
    })[0].toString();
    const fetchRequestId = yield* select(selectClusterRequestId);
    const { shouldFetchClusterList, requestId } = action.payload;
    const endpoint = acquireEndpoint(constName, requestId || fetchRequestId);

    while (true) {
      const response = yield* call(
        callApi,
        actionType,
        getData,
        null,
        endpoint,
      );
      const clusterList = response.payload.results || [];

      if (clusterList.length > 0) {
        const res = clusterList[0];
        const { response: clusterResponse } = res || {};

        if (clusterResponse?.statusCode === 200) {
          if (shouldFetchClusterList) {
            yield* put({ type: GET_CLUSTER_LIST });
            break;
          }
          break;
        } else if (clusterResponse?.statusCode >= 400) {
          yield* put({ type: FETCH_CLUSTER_REQUEST_BY_CATEGORY.FAILURE });
          yield* put(
            addGlobalAlertState({
              alertsProps: [
                {
                  descriptor: {
                    id: "aiClusterOverlay.updateProductRatingsFailure",
                    defaultMessage:
                      "Failed to fetch product ratings, please try again after some time.",
                  },
                  severity: "error",
                  variant: "standard",
                },
              ],
            }),
          );
          break;
        }
      }
      yield* delay(5000); // Delay before the next iteration
    }
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_CLUSTER_REQUEST_BY_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

function* fetchSimilarProductList(action) {
  try {
    yield* put({ type: FETCH_SIMILAR_PRODUCTS_BY_CATEGORY.REQUEST });
    const actionType = FETCH_SIMILAR_PRODUCTS_BY_CATEGORY;
    const constName = Object.keys({
      FETCH_SIMILAR_PRODUCTS_BY_CATEGORY,
    })[0].toString();
    const productId = action.payload;
    const storeId = yield* select(selectCurrentStoreId);
    const categoryId = yield* select(selectCurrentCategory);
    const payload = {
      storeId,
      categoryId,
    };
    const endpoint = acquireEndpoint(constName, productId);
    yield* call(callApi, actionType, postData, payload, endpoint);
  } catch (e: any) {
    console.error(e);
    yield* put({
      type: FETCH_SIMILAR_PRODUCTS_BY_CATEGORY.FAILURE,
      message: e.message,
    });
  }
}

export function* watchLoadClusterList() {
  yield* takeEvery(GET_CLUSTER_LIST, getClusterList);
}

export function* watchAddClusterEmbeddings() {
  yield* takeEvery(ADD_CLUSTER_EMBEDDINGS, addClusterEmbeddings);
}

export function* watchFetchRefreshClusterList() {
  yield* takeLatest(GET_CLUSTER_REQUEST_LIST, fetchClusterRequestList);
}

export function* watchfetchSimilarProductList() {
  yield* takeLatest(GET_SIMILAR_PRODUCTS_LIST, fetchSimilarProductList);
}
