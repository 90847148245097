import AppState from "../AppState";

export const productBadgeList = (state: AppState) => {
  return state.productBadge.badges ?? [];
};

export const productBadgeId = (state: AppState) => {
  return state.queryProductBadge ?? [];
};

export const selectProductBadgeDetails = (state: AppState, badgeId: string) => {
  return state.productBadge.badges?.find((badge) => badge.badgeId === badgeId);
};

export const selectSelectedStoreId = (state: AppState) => {
  return state.productBadge.selectedStore;
};

export const selectIsLoading = (state: AppState) => {
  return state.productBadge.isLoading;
};
