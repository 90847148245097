import { call, takeEvery, all, put } from "typed-redux-saga/macro";
import {
  FETCH_APP_CONFIG,
  FETCH_CALLER_ACCOUNTS,
  GET_APP_CONFIG_DETAILS,
  GET_CALLER_ACCOUNTS,
  LOAD_APP_CONFIG_SERVICE_URL,
  LOAD_ALL_ACCOUNT_DETAILS,
  LoadCallerAccountsAction,
  LoadAllAccountDetailsAction,
} from "./AppConfigTypes";
import { acquireEndpoint } from "../../utils/SmartMerchandiserAPI";
import { callApi } from "../../utils/SagaUtils";
import { getData } from "../../services/ApiService";
import { FETCH_ADAPTER_CONFIG } from "../adapter-config/AdapterConfigTypes";
import { LOAD_PLATFORM_INDEPENDENT_URL } from "../businessRules-list/BusinessRulesListTypes";
import { createUserAccountAction } from "./AppConfigActions";
import { setAdapterProps } from "utils/AppEnv";

function* getAppConfig() {
  try {
    const actionType = FETCH_APP_CONFIG;
    const constName = Object.keys({
      FETCH_APP_CONFIG,
    })[0].toString();
    const serviceType = LOAD_APP_CONFIG_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getCallerAccounts(action: LoadCallerAccountsAction) {
  try {
    const actionType = FETCH_CALLER_ACCOUNTS;
    const constName = Object.keys({
      FETCH_CALLER_ACCOUNTS,
    })[0].toString();
    const serviceType = LOAD_APP_CONFIG_SERVICE_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    yield* call(callApi, actionType, getData, null, endpoint);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getAdapterConfig() {
  try {
    const actionType = FETCH_ADAPTER_CONFIG;
    const constName = Object.keys({
      FETCH_ADAPTER_CONFIG,
    })[0].toString();
    const serviceType = LOAD_PLATFORM_INDEPENDENT_URL;

    const endpoint = acquireEndpoint(constName, serviceType);
    const response = yield* call(callApi, actionType, getData, null, endpoint);
    if (response && response.type === actionType.SUCCESS) {
      setAdapterProps({
        PRODUCT_DETAILS_MAX_WORKERS:
          response.payload?.props?.productDetailsMaxWorkers?.toString(),
        PRODUCT_DETAILS_PAGESIZE:
          response.payload?.props?.productDetailsPageSize?.toString(),
      });
    }
  } catch (error: any) {
    console.log("error", error);
  }
}

function* getAllAccountDetails(action: LoadAllAccountDetailsAction) {
  try {
    const accountId = action.payload.accountId;
    yield* all([
      getAppConfig(),
      getAdapterConfig(),
      setUserAccountId(accountId),
    ]);
  } catch (error: any) {
    console.log("error", error);
  }
}

function* setUserAccountId(accountId: string) {
  try {
    yield* put(createUserAccountAction({ accountId }));
  } catch (error: any) {
    console.log("error", error);
  }
}

export function* watchGetAppConfig() {
  yield* takeEvery(GET_APP_CONFIG_DETAILS, getAppConfig);
}

export function* watchGetCallerAccounts() {
  yield* takeEvery(GET_CALLER_ACCOUNTS, getCallerAccounts);
}

export function* watchGetAllAccountDetails() {
  yield* takeEvery(LOAD_ALL_ACCOUNT_DETAILS, getAllAccountDetails);
}
