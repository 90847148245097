/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../../../../store/AppState";
import { selectIsModalOpen } from "../../../../../store/modal/ModalSelectors";
import { Box } from "@material-ui/core";
import { Typography, useTheme } from "@mui/material";
import { setModalState } from "../../../../../store/modal/ModalActions";
import { TreeItem, TreeView } from "@material-ui/lab";
import { ExpandMore, ChevronRight } from "@material-ui/icons";
import { LoadAllCatalogs } from "../../../../../store/catalog/CatalogActions";
import { selectAllCatalogs } from "../../../../../store/catalog/CatalogSelectors";
import {
  Category,
  selectCategories,
  selectChildCategories,
} from "../../../../../store/category/CategorySelectors";
import {
  LoadChildCategories,
  LoadTopCategoriesByCatalogId,
} from "../../../../../store/category/CategoryActions";
import { ChildCategory } from "../../../../../store/category/CategoryReducers";
import { useIntl } from "react-intl";
import CustomDialog from "../../../../common/DialogContainer";
import { styles } from "./CategoryTreeModal.styles";
import CheckboxComponent from "../../../../common/Checkbox";
import ButtonComponent from "../../../../../components/common/ButtonComponent";
//import { LoadProductBadgeList } from "store/product-badge/ProductBadgeActions";

const CategoryTreeModal = ({
  storesList,
  handleCallbackFn,
  savedCategories,
  automationRulesList,
  handleCallBackForStores,
  parentData,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const { btnGroup } = styles(theme);
  const [selectedNodes, setSelectedNodes] = React.useState<string[]>([]);
  const [childCategoriesList, setChildCategoriesList] =
    React.useState<ChildCategory>();
  const [currentCatalogsId, setCurrentCatalogsId] = React.useState<string>("");
  const [currentStoreCode, setCurrentStoreCode] = React.useState<string>("");
  const [currentStoreId, setCurrentStoreId] = React.useState<string>("");
  const categoryTree = useSelector(selectCategories);
  const childCategories = useSelector(selectChildCategories);

  const catalogListNew: { name: string; id: string; code: string }[] =
    useSelector((state: AppState) => selectAllCatalogs(state));

  const handleExpand = (value) => {
    if (parentData && parentData.length) {
      parentData.forEach((data, index) => {
        if (data.storeId === value) {
          if (data.catalogs && data?.catalogs.length) {
            return;
          } else {
            dispatch(LoadAllCatalogs(value));
            return;
          }
        }
      });
    } else {
      dispatch(LoadAllCatalogs(value));
      return;
    }
  };
  React.useEffect(() => {
    const existingData = savedCategories ? [...savedCategories] : [];
    const updatedNodes: string[] = [];
    existingData?.forEach((data) => {
      if (data.id) {
        updatedNodes.push(data.id);
      }
      if (data.categoryId) {
        updatedNodes.push(data.categoryId);
      }
    });
    setSelectedNodes(updatedNodes);
  }, [savedCategories]);

  React.useEffect(() => {
    const updatedStores = storesList.map((storeData, index) => {
      if (storeData.storeId === currentStoreId) {
        storesList[index].catalogs = catalogListNew;
        return storeData;
      }
      return storeData;
    });
    handleCallBackForStores(updatedStores);
  }, [catalogListNew]);

  React.useEffect(() => {
    if (categoryTree.length) {
      parentData.map((data, index) => {
        if (data.storeId === currentStoreCode) {
          const updatedCatalogs = data.catalogs.map((catalogData, index) => {
            if (catalogData.id === currentCatalogsId) {
              data.catalogs[index].topCategories = categoryTree;
              return catalogData;
            }
            return catalogData;
          });
          parentData[index].catalogs = updatedCatalogs;
        }
        return data;
      });
      setCurrentStoreCode("");
      setCurrentCatalogsId("");

      //setTopCategories(categoryTree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryTree]);

  React.useEffect(() => {
    if (childCategories !== null) {
      setChildCategoriesList(childCategories);
    }
  }, [childCategories]);

  React.useEffect(() => {
    if (automationRulesList.name !== "") {
      storesList.map((storeData) => handleExpand(storeData.storeId));
    }
  }, [automationRulesList]);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "CategoryModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("CategoryModal", false));
    return;
  };

  //BFS algorithm to find node by his ID
  const bfsSearch = (graph, targetId) => {
    const queue = [...graph];
    while (queue.length > 0) {
      const currNode = queue.shift();
      if (currNode.id === targetId) {
        return currNode;
      }
      if (currNode.children) {
        queue.push(...currNode.children);
      }
    }
    return []; // Target node not found
  };

  // Retrieve all ids from node to his children's
  function getAllIds(node, idList: any[] = []) {
    idList.push(node.id);
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList));
    }
    return idList;
  }
  // Get IDs of all children from specific node
  const getAllChild = (id) => {
    return getAllIds(bfsSearch(categoryTree, id));
  };

  // Get all father IDs from specific node
  const getAllFathers = (id, list: any[] = []) => {
    const node = bfsSearch(categoryTree, id);
    if (node.parent) {
      list.push(node.parent);

      return getAllFathers(node.parent, list);
    }

    return list;
  };

  function isAllChildrenChecked(node, list) {
    const allChild = getAllChild(node.id);
    const nodeIdIndex = allChild.indexOf(node.id);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) =>
      selectedNodes.concat(list).includes(nodeId),
    );
  }

  const handleToggleSubCategories = (
    nodeId: string,
    catelogId: string,
    storeId: string,
  ) => {
    setCurrentStoreCode(storeId);
    setCurrentCatalogsId(catelogId);
    if (!childCategories[nodeId]) {
      dispatch(LoadChildCategories(catelogId, nodeId, "default", storeId));
    }
    // const keyLength = Object.keys(event).length;
    // if (keyLength > 0) setExpanded(value);
  };
  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation();
    const allChild = getAllChild(nodeId);
    const fathers = getAllFathers(nodeId);

    if (selectedNodes.includes(nodeId)) {
      // Need to de-check
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter(
          (id) => !allChild.concat(fathers).includes(id),
        ),
      );
    } else {
      // Need to check
      const ToBeChecked = allChild;
      for (let i = 0; i < fathers.length; ++i) {
        if (
          isAllChildrenChecked(bfsSearch(categoryTree, fathers[i]), ToBeChecked)
        ) {
          ToBeChecked.push(fathers[i]);
        }
      }
      setSelectedNodes((prevSelectedNodes) =>
        [...prevSelectedNodes].concat(ToBeChecked),
      );
    }
  };

  const handleExpandClick = (event) => {
    // prevent the click event from propagating to the checkbox
    event.stopPropagation();
  };

  const renderTree = (nodes: Category, index, catelogId, storeId) => {
    return (
      <TreeItem
        key={`2${index}_${nodes.id}`}
        nodeId={`2${index}_${nodes.id}`}
        onClick={handleExpandClick}
        onIconClick={() =>
          handleToggleSubCategories(nodes.id, catelogId, storeId)
        }
        label={
          <>
            {/* New condition added to display checkbox only when childCategories are available */}
            {childCategoriesList !== null ? (
              <CheckboxComponent
                checkboxId="CategoryTreeModal"
                checked={selectedNodes.indexOf(nodes.id) !== -1}
                handleNodeSelect={handleNodeSelect}
                handleParam={nodes.id}
              />
            ) : null}

            <Typography variant="sidebarTreeTitle">{nodes.name}</Typography>
          </>
        }
      >
        {Array.isArray(nodes.children) ? (
          nodes.children.map((node) =>
            renderTree(node, index, catelogId, storeId),
          )
        ) : nodes.childCategoryCount > 0 ? (
          <div style={{ marginLeft: 5 }}>
            <Typography variant="sidebarTreeTitle">
              {intl.formatMessage({
                id: "categoryTreeModal.load",
                defaultMessage: "Loading Data...",
              })}
            </Typography>
          </div>
        ) : null}
      </TreeItem>
    );
  };

  const handleCatalogClick = (catalogId, localeCode, storeId) => {
    setCurrentCatalogsId(catalogId);
    setCurrentStoreCode(storeId);
    dispatch(LoadTopCategoriesByCatalogId(catalogId, localeCode, storeId));
    return;
  };

  const newDataList: {
    categoryId: string;
    code: string;
    name: string;
    storeId: string;
    catalogId: string;
  }[] = [];
  const handleChildCategoriesLoop = (childData, node, storeId, catelogId) => {
    if (childData.id === node) {
      newDataList.push({
        categoryId: childData.id,
        code: childData.code,
        name: childData.name,
        storeId,
        catalogId: catelogId,
      });
    }
    if (childData && !childData?.hasOwnProperty("children")) {
      return newDataList;
    }
    if (childData && childData?.children) {
      childData.children.map((childData) => {
        if (
          selectedNodes.includes(childData.id) ||
          childData.hasOwnProperty("children")
        ) {
          return handleChildCategoriesLoop(childData, node, storeId, catelogId);
        } else {
          return newDataList;
        }
      });
    }
  };

  const handleSaveCategories = () => {
    const newSavedData = [...parentData];
    newSavedData.forEach((parentLevelData) => {
      parentLevelData?.catalogs &&
        parentLevelData?.catalogs.forEach((catalogLevelData) => {
          catalogLevelData?.topCategories &&
            catalogLevelData?.topCategories.forEach((topCategoryData) => {
              const uniqueNodes = [...new Set(selectedNodes)];
              uniqueNodes.forEach((node, nodeIndex) => {
                if (topCategoryData.id === node) {
                  newDataList.push({
                    categoryId: topCategoryData.id,
                    code: topCategoryData.code,
                    name: topCategoryData.name,
                    storeId: parentLevelData.storeId,
                    catalogId: catalogLevelData.id,
                  });
                }
              });
              if (
                topCategoryData &&
                topCategoryData?.children &&
                topCategoryData?.children.length
              ) {
                uniqueNodes.forEach((node) => {
                  topCategoryData?.children.forEach((childData) => {
                    handleChildCategoriesLoop(
                      childData,
                      node,
                      parentLevelData.storeId,
                      catalogLevelData.id,
                    );
                  });
                });
              }
            });
        });
    });
    handleCallbackFn(newDataList);
    handleClose();
  };

  const handleCancel = () => {
    dispatch(setModalState("CategoryModal", false));
    return;
  };

  const categoryTreeModalTitle = () => {
    return (
      <Typography variant="inherit">
        {intl.formatMessage({
          id: "categoryTreeModal.CategoryTitle",
          defaultMessage: "Select Category From Available Stores",
        })}
      </Typography>
    );
  };
  const categoryTreeModalContent = () => {
    return (
      <>
        <div>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            selected={[...new Set(selectedNodes)]}
            multiSelect
            onNodeSelect={(e, nodeIds) => {
              setCurrentStoreId(nodeIds[0].split("_")[1]);
              handleExpand(nodeIds[0].split("_")[1]);
            }}
          >
            {storesList &&
              storesList.map((node, index) => {
                return (
                  <TreeItem
                    onClick={handleExpandClick}
                    nodeId={`0${index}_${node.storeId}`}
                    label={
                      <Typography variant="sidebarTreeTitle">
                        {node.name}
                      </Typography>
                    }
                  >
                    {parentData && parentData.length ? (
                      parentData[index]?.catalogs &&
                      parentData[index]?.catalogs.length ? (
                        parentData[index]?.catalogs.map(
                          (catalogData, newIndex) => {
                            return (
                              <TreeItem
                                onIconClick={() =>
                                  handleCatalogClick(
                                    catalogData.id,
                                    "default",
                                    parentData[index].storeId,
                                  )
                                }
                                label={
                                  <Typography variant="sidebarTreeTitle">
                                    {catalogData.name}
                                  </Typography>
                                }
                                nodeId={`1${newIndex}_${catalogData.id}`}
                                onClick={handleExpandClick}
                              >
                                {catalogData &&
                                catalogData?.topCategories &&
                                catalogData.topCategories.length ? (
                                  catalogData.topCategories.map(
                                    (topCategory, topIndex) =>
                                      renderTree(
                                        topCategory,
                                        topIndex,
                                        catalogData.id,
                                        node.storeId,
                                      ),
                                  )
                                ) : (
                                  <div>
                                    <Typography variant="sidebarTreeTitle">
                                      {intl.formatMessage({
                                        id: "categoryTreeModal.load",
                                        defaultMessage: "Loading Data...",
                                      })}
                                    </Typography>
                                  </div>
                                )}
                              </TreeItem>
                            );
                          },
                        )
                      ) : (
                        <div style={{ marginLeft: 5 }}>
                          <Typography variant="sidebarTreeTitle">
                            {intl.formatMessage({
                              id: "categoryTreeModal.load",
                              defaultMessage: "Loading Data...",
                            })}
                          </Typography>
                        </div>
                      )
                    ) : (
                      <div style={{ marginLeft: 5 }}>
                        <Typography variant="sidebarTreeTitle">
                          {intl.formatMessage({
                            id: "categoryTreeModal.load",
                            defaultMessage: "Loading Data...",
                          })}
                        </Typography>
                      </div>
                    )}
                  </TreeItem>
                );
              })}
          </TreeView>
        </div>
      </>
    );
  };

  const categoryTreeModalActions = () => {
    return (
      <Box sx={btnGroup}>
        <ButtonComponent
          color="secondaryButtonColorCTABlue"
          variant="outlined"
          onClick={handleCancel}
        >
          {intl.formatMessage({
            id: "categoryTreeModal.cancel",
            defaultMessage: "Cancel",
          })}
        </ButtonComponent>
        <ButtonComponent
          color="primary"
          variant="contained"
          onClick={handleSaveCategories}
        >
          {intl.formatMessage({
            id: "categoryTreeModal.save",
            defaultMessage: "Save",
          })}
        </ButtonComponent>
      </Box>
    );
  };

  return (
    <Box>
      <CustomDialog
        open={isModalOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        title={categoryTreeModalTitle()}
        actions={categoryTreeModalActions()}
      >
        {categoryTreeModalContent()}
      </CustomDialog>
    </Box>
  );
};

export default CategoryTreeModal;
