import AppEnv from "../utils/AppEnv";
import { getToken as getTokenFromLocalStorage } from "../utils/tokenUtils";

interface UserService {
  initUserService: () => Promise<any>;
  isLoggedIn: () => boolean | undefined;
  login: () => false | Promise<any>;
  isLogoutSupported?: () => boolean | undefined;
  logout?: () => Promise<any> | undefined;
  accountManagement?: () => Promise<any> | undefined;
  isProfileAccessible?: () => boolean;
  getUserId: () => string | undefined;
  getUserName: () => string | undefined;
  getUserEmail: () => string | undefined;
  getUserPicture: () => string | undefined;
  getToken: () => Promise<string | undefined>;
}

const enabled = AppEnv.AUTH_ENABLED === "true";
const provider = AppEnv.AUTH_PROVIDER;
export const isSecurityEnabled = () => enabled;

let userService: UserService | undefined;

export const initUserService = async (): Promise<any> => {
  if (!enabled) throw new Error("Security disabled");

  switch (provider) {
    case "oidc":
      userService = await import("./OidcUserService");
      break;
    case "auth0":
      userService = await import("./Auth0UserService");
      break;
    case "keycloak":
    default:
      userService = await import("./KeyCloakUserService");
  }

  return await userService?.initUserService();
};

export const isLoggedIn = () => userService?.isLoggedIn();

export const login = () => userService?.login();

export const isLogoutSupported = () =>
  userService?.isLogoutSupported?.() ??
  typeof userService?.logout === "function";

export const logout = () => userService?.logout?.();

export const accountManagement = () => userService?.accountManagement?.();

export const isProfileAccessible = () =>
  userService?.isProfileAccessible?.() ??
  typeof userService?.accountManagement === "function";

export const getUserId = () => userService?.getUserId() ?? "";

export const getUserName = () => userService?.getUserName() ?? "";

export const getUserEmail = () => userService?.getUserEmail() ?? "";

export const getUserPicture = () => userService?.getUserPicture() ?? "";

export const getToken = async () => {
  const token = userService?.getToken() || getTokenFromLocalStorage();

  return token;
};
