export const IS_WIDGET_ENABLED =
  process.env.REACT_APP_ENABLE_SM_WIDGET === "true";
export const CDN_URL = IS_WIDGET_ENABLED
  ? process.env.REACT_APP_CDN_URL || "https://d2lpyjk8ylemuy.cloudfront.net"
  : window.location.origin;
export const CDN_WIDGET_URL = IS_WIDGET_ENABLED ? `${CDN_URL}/widget` : CDN_URL;
export const CDN_WIDGET_LANG_URL = `${CDN_WIDGET_URL}/lang`;

const REDIRECTION_PATHNAME_AFTER_LOGIN = "/app-smartmerchview-dashboard";
const DEFAULT_PATHNAME_AFTER_LOGIN = "/";

function isSyndigoDomain() {
  const domain = window.location.hostname;

  return domain.endsWith("syndigo.com");
}

function isInsideIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function removeDotFromStart(path: string) {
  return path.replace(/^\./, "");
}

export function getRedirectionPathnameAfterLogin() {
  return isSyndigoDomain()
    ? REDIRECTION_PATHNAME_AFTER_LOGIN
    : DEFAULT_PATHNAME_AFTER_LOGIN;
}

export function redirectTopWindow() {
  const isIframe = isInsideIframe(),
    windowTop = window.top;

  if (!isIframe || !windowTop) return false;

  windowTop.location.pathname = getRedirectionPathnameAfterLogin();
}
