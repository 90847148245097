import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
//import AppBar from "@mui/material/AppBar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useDispatch, useSelector } from "react-redux";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import SettingsIcon from "@material-ui/icons/Settings";

import TabsMenu from "../TabsMenu";
import defaultLogo from "../../assets/new-logo.png";
import {
  getUserName,
  getUserPicture,
  getUserEmail,
  logout,
} from "../../services/UserService";
import UserAvatar from "../profile/UserAvatar";
import SearchBox from "./SearchBox";
import { setModalState } from "../../store/modal/ModalActions";
import { shouldUpdateEditSequenceList } from "../../store/product-list/ProductListSelectors";
import { useLocation } from "react-router-dom";
import { isLogoutSupported } from "../../services/UserService";
import MenuUI from "./MenuUI";
import {
  selectAppLogo,
  selectConfigValue,
} from "../../store/app-config/AppConfigSelectors";
import { selectProductAlertsListFetched } from "../../store/product-alerts-modal/ProductAlertsListSelectors";
import AppState from "../../store/AppState";
import InteractiveMenu from "../common/InteractiveMenu";
import { LogoutConfirmationModalId } from "./LogoutConfirmationModal";
import Icons from "components/common/Icons";
import { CDN_WIDGET_URL, removeDotFromStart } from "utils/WidgetUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    root: {
      color: "black",
    },
    logo: {
      height: "40px",
      marginRight: "40px",
    },
    menuButton: {
      minWidth: "100px",
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    logoutIcon: {
      height: 50,
      width: 50,
      marginTop: 5,
      marginLeft: 2,
    },
  }),
);

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const [configAnchorEl, setConfigAnchorEl] = React.useState<string>("");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [menuIconSelected, setMenuIconSelected] = useState<string>("");
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const shouldUpdateEditSequenceListFlag = useSelector(
    shouldUpdateEditSequenceList,
  );
  const appLogo = useSelector(selectAppLogo);
  const notificationsData = useSelector(selectProductAlertsListFetched);

  const location = useLocation();
  const url = location.pathname;
  const isInReportsTab = url.includes("reports");
  const isProductAlertsEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "enableProductAlerts"),
  );
  const isProductBadgeEnabled = useSelector((state: AppState) =>
    selectConfigValue(state, "badgeOverlayEnabled"),
  );

  const handleProfileMenuOpen = (
    value: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    if (value !== "") {
      setMenuIconSelected(value);
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleConfigMenuOpen = (
    value: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setMenuIconSelected(value);
    setAnchorEl(event?.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setMenuIconSelected("");
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openProductAlertsListModal = () => {
    dispatch(setModalState("ProductAlertsListModal", true));
  };

  const logoutSupported = isLogoutSupported();
  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <InteractiveMenu
      menuId="MenuItemWithSubItems"
      handleClose={handleMobileMenuClose}
      open={isMobileMenuOpen}
      classes={classes}
      anchorEl={mobileMoreAnchorEl}
      mobileMenuId={mobileMenuId}
      logoutSupported={logoutSupported}
      isInReportsTab={isInReportsTab}
      shouldUpdateEditSequenceListFlag={shouldUpdateEditSequenceListFlag}
      handleMenuClose={handleMenuClose}
      handleProfileMenuOpen={handleProfileMenuOpen}
    />
  );

  return (
    <div className={classes.grow} style={{ margin: 0 }}>
      <AppBar
        style={{ background: "#fff" }}
        position="static"
        className={classes.root}
      >
        <Toolbar>
          <img
            src={
              appLogo || `${CDN_WIDGET_URL}${removeDotFromStart(defaultLogo)}`
            }
            alt="Smart Merchandiser Logo"
            className={classes.logo}
          />
          <TabsMenu />
          <SearchBox />
          <div className={classes.sectionDesktop}>
            {isProductAlertsEnabled === "true" &&
              (notificationsData.length ? (
                <IconButton
                  onClick={openProductAlertsListModal}
                  aria-label={`show ${notificationsData[0].SKUs.length} new notifications`}
                  color="inherit"
                >
                  <Badge
                    badgeContent={notificationsData[0].SKUs.length}
                    color="primary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  onClick={openProductAlertsListModal}
                  aria-label={`show ${notificationsData.length} new notifications`}
                  color="inherit"
                >
                  <NotificationsNoneOutlinedIcon />
                </IconButton>
              ))}
            {(isProductAlertsEnabled === "true" ||
              isProductBadgeEnabled === "true") && (
              <IconButton
                aria-label="config-menu"
                id="config-menu"
                onClick={(e) => handleConfigMenuOpen("config-menu", e)}
              >
                <SettingsIcon />
              </IconButton>
            )}

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={(e) => handleProfileMenuOpen("profile", e)}
              color="inherit"
            >
              <UserAvatar
                name={getUserName()}
                picture={getUserPicture()}
                email={getUserEmail()}
              />
            </IconButton>
            {logoutSupported && (
              <IconButton
                edge="end"
                aria-label="log out"
                aria-haspopup="true"
                onClick={() => {
                  if (!isInReportsTab && shouldUpdateEditSequenceListFlag) {
                    dispatch(setModalState(LogoutConfirmationModalId, true));
                  } else {
                    logout();
                  }
                  handleMenuClose();
                }}
                color="inherit"
                className={classes.logoutIcon}
              >
                <Icons iconId="SignoutIcon" />
              </IconButton>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Icons iconId="MoreVerticalIcon" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <MenuUI
        anchor={anchorEl}
        selected={menuIconSelected}
        handleClose={handleMenuClose}
      />
    </div>
  );
}
