import {
  ADD_CLUSTER_EMBEDDINGS,
  GET_CLUSTER_LIST,
  GET_CLUSTER_REQUEST_LIST,
  GET_SIMILAR_PRODUCTS_LIST,
  RESET_CLUSTER_LIST,
  RESET_SELECTED_SMART_SORT,
  SET_SELECTED_SMART_SORT,
  getClusterRefreshPayload,
} from "./AiClusterType";

export const fetchClusterListRequestAction = () => {
  return {
    type: GET_CLUSTER_LIST,
  };
};

export const addClusterEmbeddingsRequestAction = (payload) => {
  return {
    type: ADD_CLUSTER_EMBEDDINGS,
    payload,
  };
};

export const fetchRefershClusterRequestAction = (
  payload: getClusterRefreshPayload,
) => {
  return {
    type: GET_CLUSTER_REQUEST_LIST,
    payload,
  };
};

export const getSimilarProductsList = (payload) => {
  return {
    type: GET_SIMILAR_PRODUCTS_LIST,
    payload,
  };
};

export const setSelectedSmartSort = (payload) => {
  return {
    type: SET_SELECTED_SMART_SORT,
    payload,
  };
};

export const resetSelectedSmartSort = () => {
  return {
    type: RESET_SELECTED_SMART_SORT,
  };
};

export const resetClusterList = () => {
  return {
    type: RESET_CLUSTER_LIST,
  };
};
