import AppState from "../AppState";
import { productClusterList } from "./AiClusterType";

export const selectProductClusterListFetched = (
  state: AppState,
): productClusterList[] => {
  return state?.productsCluster.productClusterList ?? [];
};

export const selectClusterRefreshResp = (state: AppState) => {
  return state?.productsCluster.clusterRequestList;
};

export const selectIsProductRequestListFetched = (state: AppState) => {
  return state?.productsCluster.isFetchingProductClusterRequestList;
};

export const selectClusterRequestId = (state: AppState) => {
  return state?.productsCluster.addClusterEmbeddings.requestId;
};

export const selectIsClusterEmbeddingsRequested = (state: AppState) => {
  return state?.productsCluster.isClusterEmbeddingsAdded;
};

export const selectSimilarProducts = (state: AppState) => {
  return state?.productsCluster.similarProductsList;
};

export const selectIsSimilarProductsFetched = (state: AppState) => {
  return state.productsCluster.isFetchingSimilarProductsList;
};

export const selectIsAllSimilarFetched = (state: AppState) => {
  return state.productsCluster.isSimilarProductFetched;
};

export const selectIsClusterResponseFetched = (state: AppState) => {
  return (
    state.productsCluster?.clusterRequestList[0]?.response?.statusCode ===
      200 || false
  );
};

export const selectedSmartSortValue = (state: AppState) => {
  return state.productsCluster.selectedSmartSort;
};
