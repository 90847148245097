export const FETCH_CLUSTER_LIST = {
  REQUEST: "FETCH_CLUSTER_LIST_REQUEST",
  SUCCESS: "FETCH_CLUSTER_LIST_SUCCESS",
  FAILURE: "FETCH_CLUSTER_LIST_FAILURE",
};

export const GET_CLUSTER_LIST = "GET_CLUSTER_LIST";

export const ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY = {
  REQUEST: "ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY_REQUEST",
  SUCCESS: "ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY_SUCCESS",
  FAILURE: "ADD_CLUSTER_EMBEDDINGS_BY_CATEGORY_FAILURE",
};

export const ADD_CLUSTER_EMBEDDINGS = "ADD_CLUSTER_EMBEDDINGS";

export const FETCH_CLUSTER_REQUEST_BY_CATEGORY = {
  REQUEST: "FETCH_CLUSTER_REQUEST_BY_CATEGORY_REQUEST",
  SUCCESS: "FETCH_CLUSTER_REQUEST_BY_CATEGORY_SUCCESS",
  FAILURE: "FETCH_CLUSTER_REQUEST_BY_CATEGORY_FAILURE",
};

export const GET_CLUSTER_REQUEST_LIST = "GET_CLUSTER_REQUEST_LIST";

export const FETCH_SIMILAR_PRODUCTS_BY_CATEGORY = {
  REQUEST: "FETCH_SIMILAR_PRODUCTS_BY_CATEGORY_REQUEST",
  SUCCESS: "FETCH_SIMILAR_PRODUCTS_BY_CATEGORY_SUCCESS",
  FAILURE: "FETCH_SIMILAR_PRODUCTS_BY_CATEGORY_CATEGORY_FAILURE",
};

export const GET_SIMILAR_PRODUCTS_LIST = "GET_SIMILAR_PRODUCTS_LIST";

export const SET_SELECTED_SMART_SORT = "SET_SELECTED_SMART_SORT";
export const RESET_SELECTED_SMART_SORT = "RESET_SELECTED_SMART_SORT";

export const RESET_CLUSTER_LIST = "RESET_CLUSTER_LIST";

export interface clusterElmbeddings {
  requestId: string;
}

export interface requestClusterList {
  accountId: string;
  request: string;
  categoryId: string;
  requestId: string;
  response: responseObj;
}

export interface productClusterList {
  cluster_score: number;
  cluster_id: number;
  product_similarity: {
    name: number;
    description: number;
    attributes: number;
  };
  categoryId: string;
  score: number;
  storeId: string;
  productId: string;
  rank: string;
}

export interface responseObj {
  statusCode: number;
  body: {
    status: string;
    message: string;
  };
}

export interface similarProductsList {
  results: productClusterList[];
}

export interface SubsitutionManagementProductData {
  code?: string;
  type?: string;
  colorId?: string;
  name?: string;
  swatchImage?: string;
  baseProductId?: string | undefined;
  thumbnail?: string;
  isPublished?: boolean;
  storeSpecific?:
    | { storeId: string; isPublished: boolean | null }[]
    | undefined;
  translations?: [
    {
      localeCode: string;
      name: string;
    },
  ];
  stock?: Number;
  hasSequenceableColors?: boolean;
  price?: Number;
  bounds?: ClientRect | DOMRect | null;
  isUpdatingPublishFlag?: boolean;
  lowPrice?: number;
  highPrice?: number;
  typeCode: string;
}

export interface getClusterRefreshPayload {
  requestId: string;
  shouldFetchClusterList: boolean;
}
