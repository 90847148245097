import { AttributeData, AttributeValue } from "../store/category/CategoryTypes";
import {
  SelectType,
  MappedValue,
  AttributesList,
} from "store/businessRules-list/BusinessRulesListTypes";

export const getAttributeDisplayName = (
  attribute: AttributeData,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    attribute.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attribute.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeDisplayName = desc
    ? desc.displayName
    : attribute.code
      ? attribute.code
      : attribute.attributeId;

  return attributeDisplayName;
};

export const getAttributeValueDisplayName = (
  attributeValue: AttributeValue,
  localeCode: string | null,
  defaultLocaleCode: string,
): string => {
  const desc =
    attributeValue.descriptions.find(
      (description) => description.localeCode === localeCode,
    ) ||
    attributeValue.descriptions.find(
      (description) => description.localeCode === defaultLocaleCode,
    );
  const attributeValueDisplayName = desc
    ? desc.displayValue
    : attributeValue.code
      ? attributeValue.code
      : attributeValue.attributeValueId;

  return attributeValueDisplayName;
};

export const handleAttributeValues = (
  value: string | null,
  attributesList: AttributesList[],
  setMappedValues: (
    callback: (prevState: MappedValue[]) => MappedValue[],
  ) => void,
) => {
  const filteredData = attributesList.find((attribute) =>
    attribute.label === value || attribute.attributeId === value
      ? attribute
      : null,
  );

  if (!filteredData) return;

  const matchingValuesNew: SelectType[] = [];
  filteredData.values.map((data: any) => {
    matchingValuesNew.push({ label: data.label, value: data.valueId });
    return matchingValuesNew;
  });

  setMappedValues((prevState) => [
    ...prevState,
    {
      label: filteredData.label,
      values: matchingValuesNew,
    },
  ]);
};

export const handleMappedValues = (
  attributeLabel: string,
  mappedValues: MappedValue[],
) => {
  if (mappedValues.length) {
    const result = mappedValues?.filter(
      (mappedData) => mappedData.label === attributeLabel,
    );
    return result.length ? result[0].values : [];
  }
  return mappedValues;
};
