import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDrop, useDrag, DropTargetMonitor, XYCoord } from "react-dnd";
import { makeStyles, Typography, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FacetValue from "./FacetValue";
import { setModalState } from "../../store/modal/ModalActions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { FacetActionConfirmationModalId } from "./FacetActionConfirmationModal";
import { FACET_ACTION_TYPE } from "../../utils/Constants";
import {
  selectCurrentStoreId,
  selectCurrentLocale,
} from "../../store/store-list/StoreListSelectors";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import {
  updateInheritedFacet,
  updateFacetBucketSequences,
  addFacetBucket,
  updateFacetBucketDescription,
} from "../../store/facets/FacetActions";
import blockIcon from "../../assets/block-icon.svg";
import unblockIcon from "../../assets/unblock-icon.svg";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  FacetBucket,
  FacetBucketSequences,
  FacetBucketDescription,
} from "../../store/facets/FacetTypes";
import { getFacetBucketDisplayName } from "../../utils/FacetUtils";
import { editFacetBucketModalId } from "./EditFacetBucketModal";
import { useIntl } from "react-intl";
import { selectConfigValue } from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";
import Icons from "components/common/Icons";
import { CDN_WIDGET_URL, removeDotFromStart } from "utils/WidgetUtil";

const useStyles = makeStyles({
  root: {
    marginBottom: ".5rem",
    cursor: (prop: StyleProps) => prop.cursorType,
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    borderRadius: 4,
    padding: 5,
    width: "100%",
    "& .facet-icons": {
      display: "none",
    },
    "&:hover .facet-icons": {
      display: "block",
    },
    "&:hover .facet-name-block": {
      maxWidth: 130,
    },
  },
  facetHeader: {
    padding: "0.5rem 1rem",
    position: "relative",
    fontWeight: "bold",
  },
  addValue: {
    cursor: "pointer",
    display: "flex",
    padding: "2px",
    margin: "3px 5px",
    width: "calc(100% - 10px)",
  },
  addIcon: {
    fontSize: "1rem",
    height: "auto",
  },
  textarea: {
    borderRadius: 4,
    boxShadow:
      "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: "2px 5px",
    margin: "3px 5px",
    width: "calc(100% - 10px)",
    resize: "none",
  },
  editIcon: {
    cursor: "pointer",
    fontSize: "0.875rem",
    position: "absolute",
    right: 24,
    top: 10,
  },
  badge: {
    marginTop: 8,
    width: "100%",
    "& .MuiBadge-badge": {
      padding: 0,
    },
  },
  badgeIcon: {
    cursor: "pointer",
    fontSize: "1rem",
    position: "absolute",
    right: 6,
    top: 10,
  },
  dropDownIcon: {
    top: 9,
    fontSize: "1.125rem",
    position: "absolute",
    cursor: "pointer",
  },
  dragIcon: {
    top: 10,
    left: 0,
    fontSize: "1rem",
    position: "absolute",
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.5)",
  },
  facetName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  italicDisplay: {
    fontStyle: "italic",
  },
  blockIcon: {
    height: 16,
  },
  unblockIcon: {
    top: 8,
    right: 4,
    height: 20,
  },
});

interface Props {
  index: number;
  facetId: string;
  facetType: string;
  attributeId: string;
  displayName: string;
  facetBuckets: FacetBucket[];
  onMove: (dragItem: DragFacetItem) => void;
  setHoverIndex: React.Dispatch<React.SetStateAction<number>>;
  isSortBySequence: boolean;
  facetCategoryId: string | null;
  isEnabled: boolean;
}

interface StyleProps {
  cursorType: string;
}

export interface DragFacetItem {
  id: string;
  index: number;
  type: string;
}

const FacetBucketTile = ({
  index,
  facetId,
  facetType,
  attributeId,
  displayName,
  facetBuckets,
  onMove,
  setHoverIndex,
  isSortBySequence,
  facetCategoryId,
  isEnabled,
}: Props) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [buckets, setBuckets] = useState<FacetBucket[]>([]);
  const [hoverValue, setHoverValue] = useState(-1);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [newFacetValue, setNewFacetValue] = useState("");
  const [showValues, setShowValues] = useState(false);
  const storeId = useSelector(selectCurrentStoreId);
  const categoryId = useSelector(selectCurrentCategory);
  const localeCode = useSelector(selectCurrentLocale);
  const dispatch = useDispatch();
  const isInherited = facetCategoryId ? facetCategoryId !== categoryId : false;
  const inheritedFacetActionImgSrc =
    isInherited && isEnabled
      ? `${CDN_WIDGET_URL}${removeDotFromStart(blockIcon)}`
      : `${CDN_WIDGET_URL}${removeDotFromStart(unblockIcon)}`;
  const showValueTiles = showValues && (!isInherited || isEnabled);
  const cursorType = isInherited ? "default" : "move";
  const styleProps: StyleProps = {
    cursorType,
  };
  const classes = useStyles(styleProps);
  const displayNameClasses = isInherited
    ? `facet-name-block ${classes.italicDisplay}`
    : "facet-name-block";
  const blockImageClasses =
    isInherited && isEnabled
      ? `${classes.badgeIcon} ${classes.blockIcon} facet-icons`
      : `${classes.badgeIcon} ${classes.unblockIcon} facet-icons`;

  const defaultLocaleId = useSelector((state: AppState) =>
    selectConfigValue(state, "defaultLocaleId"),
  );

  useEffect(() => {
    setBuckets(facetBuckets);
  }, [facetBuckets]);

  const moveFacetValues = useCallback(
    (dragItem: DragFacetItem) => {
      if (hoverValue < 0) return;
      const copiedList = buckets.slice();
      const dragIndex = copiedList.findIndex(
        (item) => item.facetBucketId === dragItem.id,
      );
      if (dragIndex === hoverValue) return;
      const draggedFacet = copiedList[dragIndex];
      copiedList.splice(dragIndex, 1);
      copiedList.splice(hoverValue, 0, draggedFacet);
      const facetBucketSequences: FacetBucketSequences[] = [];
      let prevSequence = 0;
      copiedList.forEach(function (value, index) {
        var nextIndex = index + 1;
        var thisSequence = Number(value.sequence);

        var nextSequence =
          copiedList.length !== nextIndex
            ? Number(copiedList[nextIndex].sequence)
            : thisSequence;

        if (prevSequence >= thisSequence || thisSequence > nextSequence) {
          var rounder = Math.pow(10, 2);
          thisSequence =
            prevSequence < nextSequence
              ? (prevSequence + nextSequence) / 2
              : prevSequence + 1;
          thisSequence = Math.round(thisSequence * rounder) / rounder;

          if (prevSequence === thisSequence) {
            thisSequence = prevSequence + 1;
          }

          facetBucketSequences.push({
            facetBucketId: value.facetBucketId,
            sequence: thisSequence,
          });
        }
        prevSequence = thisSequence;
      });
      const payload = {
        facetBucketSequences,
        facetId,
      };

      dispatch(updateFacetBucketSequences(payload));
      setBuckets(copiedList);
      setHoverValue(-1);
    },
    [buckets, hoverValue, dispatch, facetId],
  );

  const editValue = useCallback(
    (newValue: string, index: number) => {
      const copiedValues = buckets.slice();
      const valueObj = copiedValues[index];
      if (localeCode) {
        valueObj.descriptions.forEach((description) => {
          if (
            description.localeCode === localeCode &&
            description.displayValue !== newValue
          ) {
            description.displayValue = newValue;
            const payload = {
              localeCode,
              facetBucketId: valueObj.facetBucketId,
              displayValue: newValue,
            };
            dispatch(updateFacetBucketDescription(payload));
          }
        });
      }
      setBuckets(copiedValues);
    },
    [buckets, dispatch, localeCode],
  );

  const facetTiles = buckets.map((bucket, index) => {
    const facetValueDisplayName = getFacetBucketDisplayName(
      bucket,
      localeCode,
      defaultLocaleId,
    );
    return (
      <FacetValue
        key={bucket.facetBucketId}
        index={index}
        facetId={facetId}
        facetBucketId={bucket.facetBucketId}
        attributeId={attributeId}
        attributeValueId={bucket.facetBucketId}
        displayName={facetValueDisplayName}
        onMove={moveFacetValues}
        setHoverIndex={setHoverValue}
        updateFacetValues={editValue}
        isSortBySequence={isSortBySequence}
        isFacetBucket={true}
        bucketValues={bucket.values}
      />
    );
  });

  const [, drop] = useDrop({
    accept: "Facets",
    hover(item: DragFacetItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      setHoverIndex(hoverIndex);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: "Facets", id: facetId, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      onMove(monitor.getItem());
    },
  });

  const opacity = isDragging ? 0 : 1;
  if (!isInherited) drag(drop(ref));

  const handleValueAddClick = useCallback(() => {
    setIsAddOpen(true);
  }, [setIsAddOpen]);

  const blurHandler = () => {
    setNewFacetValue("");
    setIsAddOpen(false);
  };

  const keyDownHandler = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      event.persist();
      if (event.keyCode === 13) {
        const trimmed = newFacetValue.trim();
        if (trimmed !== "") {
          const copiedList = buckets.slice();
          const lastValue = copiedList[copiedList.length - 1];
          const sequence = lastValue ? lastValue.sequence + 1 : 1;
          const descriptions: FacetBucketDescription[] = [
            {
              displayValue: trimmed,
              localeCode: localeCode ? localeCode : "",
            },
          ];
          copiedList.push({
            facetBucketId: facetId + ":" + trimmed,
            sequence,
            isDefault: false,
            threshold: 0,
            descriptions,
            values: [],
          });

          const payload = {
            facetBucket: {
              facetBucketId: facetId + ":" + trimmed,
              facetId,
              sequence,
              isDefault: false,
              descriptions:
                descriptions.length > 0
                  ? {
                      data: descriptions[0],
                    }
                  : null,
              values: null,
            },
            facetBucketId: facetId + ":" + trimmed,
            localeCode: localeCode ? localeCode : "",
          };

          dispatch(addFacetBucket(payload));
          setBuckets(copiedList);
        }
        setIsAddOpen(false);
        setNewFacetValue("");
      }
    },
    [buckets, newFacetValue, localeCode, dispatch, facetId],
  );

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewFacetValue(event.target.value);
  };

  const onEditIconClick = () => {
    dispatch(setModalState(editFacetBucketModalId, true, { facetId }));
  };

  const handleBadgeClick = () => {
    const payload = {
      facetId,
      categoryId,
      storeId,
    };
    dispatch(
      setModalState(FacetActionConfirmationModalId, true, {
        actionType: FACET_ACTION_TYPE.DELETE,
        payload,
      }),
    );
  };

  const handleInheritedFacetUpdate = () => {
    if (categoryId) {
      let facetIdSplits = facetId.split(":");
      if (facetIdSplits[1]) facetIdSplits[1] = categoryId;
      dispatch(
        updateInheritedFacet({
          categoryId,
          facetId: facetIdSplits.join(":"),
          isEnabled,
          attributeId,
          type: facetType,
        }),
      );
    }
  };

  return (
    <div ref={ref} style={{ opacity }} className={classes.root}>
      <div className={classes.facetHeader}>
        {!isInherited && (
          <DragIndicatorIcon className={`${classes.dragIcon} facet-icons`} />
        )}
        <div className={`${classes.facetName} ${displayNameClasses}`}>
          {displayName}
          {(!isInherited || isEnabled) && (
            <Icons
              iconId="TriangleDownIcon"
              classes={classes.dropDownIcon}
              onClick={(e) => setShowValues(!showValues)}
            />
          )}
        </div>
        {
          <Tooltip
            title={intl.formatMessage({
              id: "facetBucketTile.editTooltipText",
              defaultMessage: "Edit values for multiple locales",
            })}
            interactive
            enterDelay={500}
          >
            <Icons
              iconId="EditIcon"
              classes={`${classes.editIcon} facet-icons`}
              onClick={onEditIconClick}
            />
          </Tooltip>
        }
        {!isInherited && (
          <Tooltip
            title={intl.formatMessage({
              id: "facetBucketTile.removeTooltipText",
              defaultMessage: "Remove facet from category",
            })}
            interactive
            enterDelay={500}
          >
            <CloseIcon
              className={`${classes.badgeIcon} facet-icons`}
              onClick={handleBadgeClick}
            />
          </Tooltip>
        )}
        {isInherited && (
          <Tooltip
            title={
              isInherited && isEnabled
                ? intl.formatMessage({
                    id: "facetBucketTile.blockInheritenceTooltip",
                    defaultMessage: "Block facet from being inherited",
                  })
                : intl.formatMessage({
                    id: "facetBucketTile.unblockInheritenceTooltip",
                    defaultMessage: "Unblock facet from being inherited",
                  })
            }
            interactive
            enterDelay={500}
          >
            <img
              src={inheritedFacetActionImgSrc}
              alt="Block facet"
              className={blockImageClasses}
              onClick={handleInheritedFacetUpdate}
            />
          </Tooltip>
        )}
      </div>
      <div>
        {showValueTiles && facetTiles}
        {isAddOpen && (
          <textarea
            className={classes.textarea}
            aria-label="Add new facet bucket"
            onBlur={blurHandler}
            autoFocus={true}
            onKeyDown={keyDownHandler}
            onChange={changeHandler}
          />
        )}
        {showValueTiles && !isAddOpen && (
          <div className={classes.addValue} onClick={handleValueAddClick}>
            <AddIcon className={classes.addIcon} />
            <Typography variant="body2">
              {intl.formatMessage({
                id: "facetBucketTile.addBucketText",
                defaultMessage: "Add Bucket",
              })}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(FacetBucketTile);
