import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AppState from "../../store/AppState";
import { selectIsModalOpen } from "../../store/modal/ModalSelectors";
import { setModalState } from "../../store/modal/ModalActions";
import { selectUserLocale } from "../../store/user/UserSelectors";
import { setUserLocale } from "../../store/user/UserActions";
import CustomDialog from "../common/DialogContainer";
import { Theme, useTheme } from "@mui/material";
import DropdownMenu from "../common/DropdownMenu";

const useStyles = (theme: Theme) => {
  return {
    formControl: {
      width: "80%",
    },
  };
};

interface Props {}

const PreferencesModal: React.FC<Props> = (props) => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const locale = useSelector(selectUserLocale);

  const isModalOpen = useSelector((state: AppState) =>
    selectIsModalOpen(state, "PreferencesModal"),
  );

  const handleClose = () => {
    dispatch(setModalState("PreferencesModal", false));
  };

  const handleLocaleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;
    dispatch(setUserLocale(changeEvent.target.value));
    window.localStorage.setItem(
      "userLocalePreference",
      changeEvent.target.value,
    );
  };

  const preferencesModalTitle = () => {
    return (
      <Typography variant="h6">
        {intl.formatMessage({
          id: "preferencesModal.modalTitle",
          defaultMessage: "Preferences",
        })}
      </Typography>
    );
  };

  const preferencesModalContent = () => {
    return (
      <>
        <FormControl style={classes.formControl}>
          <InputLabel id="locale-list-label">
            {intl.formatMessage({
              id: "preferencesModal.localeSelectionLabel",
              defaultMessage: "Locale Selection",
            })}
          </InputLabel>
          <DropdownMenu
            menuId="PreferencesModal"
            value={locale}
            changeHandler={handleLocaleChange}
          />
        </FormControl>
      </>
    );
  };
  return (
    <CustomDialog
      open={isModalOpen}
      onClose={() => handleClose()}
      title={preferencesModalTitle()}
      fullWidth
      maxWidth={"sm"}
    >
      {preferencesModalContent()}
    </CustomDialog>
  );
};

export default PreferencesModal;
