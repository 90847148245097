import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSortedProducts,
  loadSortingList,
  setSortingRule,
} from "../store/product-list/ProductListActions";
import AnalyticsSorting from "./AnalyticsSorting";
import {
  selectSortingListLoadedState,
  selectSortingRulesList,
} from "../store/product-list/ProductListSelectors";
import { Typography, FormControl, Skeleton } from "@mui/material";
import {
  BusinessRulesListDetails,
  ProductSequenceData,
} from "../store/product-list/ProductListTypes";
import { useIntl } from "react-intl";
import { calculateStartEndDate } from "../utils/ProductUtil";
import {
  selectIsProductAnalyticsFetched,
  selectProductCodes,
} from "../store/product/ProductSelectors";
import { loadProductAnalytics } from "../store/product/ProductActions";
import {
  periodTypes,
  DEFAULT_SORT_PERIOD_VALUE,
  analyticMetrics,
} from "../utils/Constants";
import {
  loadSystemGeneratedRules,
  setIsAnalytic,
} from "../store/businessRules-list/BusinessRulesListActions";
import {
  selectIsAnalytic,
  selectSystemGeneratedRules,
} from "../store/businessRules-list/BusinessRuleslistSelectors";
import moment from "moment-timezone";
import { styles } from "./SortingTools.styles";
import ButtonComponent from "../components/common/ButtonComponent";
import DropdownMenu from "./common/DropdownMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
    },
    arrowIcon: {
      position: "absolute",
      right: 0,
    },
    menuButton: {
      width: 200,
      justifyContent: "left",
    },
    menuPopper: {
      width: 200,
      zIndex: 1,
    },
    tickIcon: {
      position: "absolute",
      left: 5,
      fontSize: "1.25rem",
      width: "0.75em",
    },
    listItem: {
      paddingLeft: 20,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    button: {
      padding: 5,
      backgroundColor: "rgb(241, 131, 8)",
      color: "#fff",
      fontSize: 16,
      "&:hover": {
        backgroundColor: "rgb(241, 131, 8)",
        color: "#fff",
      },
      textTransform: "none",
      marginLeft: 20,
    },
    sortClass: {
      display: "flex",
      alignItems: "center",
    },
  }),
);

interface Props {
  products: ProductSequenceData[];
}

const SortingTools = (props: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { bodyWrap, select, skeleton, muiListSubheader, muiMenuItem } =
    styles();

  const dispatch = useDispatch();
  const [selectedRule, setSelectedRule] = useState("");
  const [selectedSortPeriod, setSelectedSortPeriod] = useState("");
  const sortingRulesList = useSelector(selectSortingRulesList);
  const systemGeneratedRulesList = useSelector(selectSystemGeneratedRules);

  const sortedSystemGeneratedRulesList = systemGeneratedRulesList
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const allSortingRulesList = [
    ...sortingRulesList,
    ...sortedSystemGeneratedRulesList,
  ];
  const isSortingRulesListLoaded = useSelector(selectSortingListLoadedState);
  const productCodes = useSelector(selectProductCodes);
  const isAnalyticsFetched = useSelector(selectIsProductAnalyticsFetched);
  const isAnalyticMetric = useSelector(selectIsAnalytic);
  let currentUserTimezone = moment.tz.guess();
  const isAllSortingRulesFetched =
    sortingRulesList.length > 0 || sortedSystemGeneratedRulesList.length > 0;

  React.useEffect(() => {
    dispatch(loadSortingList());
    dispatch(loadSystemGeneratedRules());
  }, [dispatch]);

  React.useEffect(() => {
    if (!selectedRule && isAnalyticMetric) dispatch(setIsAnalytic(false));
  }, [dispatch, selectedRule, isAnalyticMetric]);

  const handleChange = (event) => {
    setSelectedRule(event.target.value);
    dispatch(setSortingRule(event.target.value));
    if (isAnalyticMetric) {
      dispatch(setIsAnalytic(false));
    } else if (selectedSortPeriod !== DEFAULT_SORT_PERIOD_VALUE) {
      setSelectedSortPeriod(DEFAULT_SORT_PERIOD_VALUE);
    }
    allSortingRulesList.forEach((sortingRule) => {
      if (
        sortingRule.ruleId === event.target.value &&
        (sortingRule.ruleType === "WS" || sortingRule.ruleType === "MCS")
      ) {
        const isAnalytic = sortingRule.formula?.some((formula) =>
          analyticMetrics.includes(formula.metric),
        );
        dispatch(setIsAnalytic(isAnalytic));
        return;
      }
    });
  };

  const handleSortPeriod = (event) => {
    setSelectedSortPeriod(event.target.value);
  };
  const handleSortData = () => {
    if (isAnalyticMetric) {
      dispatch(
        loadSortedProducts(
          selectedRule,
          selectedSortPeriod,
          currentUserTimezone,
        ),
      );
    } else {
      dispatch(loadSortedProducts(selectedRule));
    }

    if (isAnalyticsFetched) {
      const sortingRule = allSortingRulesList.find(
        (rule) => rule.ruleId === selectedRule,
      );
      if (sortingRule && selectedSortPeriod !== "") {
        switch (sortingRule.ruleType) {
          case "MCS":
            const wsCriteria = sortingRule.criteria.find(
              (c) => c.ruleType === "WS",
            );
            if (wsCriteria) {
              wsCriteria.dateRange = selectedSortPeriod;
            }
            break;
          case "WS":
            sortingRule.dateRange = selectedSortPeriod;
            break;
        }
      }
      const { startDate, endDate } = calculateStartEndDate(
        sortingRule as BusinessRulesListDetails,
      );
      if (startDate && endDate) {
        const analyticsPayload = {
          startDate: startDate,
          endDate: endDate,
          productCodes: productCodes,
        };
        dispatch(loadProductAnalytics(analyticsPayload));
      }
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <AnalyticsSorting />
        <div className={classes.sortClass}>
          {!isAllSortingRulesFetched && !isSortingRulesListLoaded ? (
            <div>
              <Skeleton variant="rectangular" width={212} height={32} />
            </div>
          ) : (
            <>
              <FormControl sx={bodyWrap}>
                <label>
                  <Typography variant="subHeader">
                    {intl.formatMessage({
                      id: "sortingTools.label",
                      defaultMessage: "Sorting Rule",
                    })}
                  </Typography>
                </label>
                <DropdownMenu
                  menuId="SortingTools"
                  classes={classes}
                  changeHandler={handleChange}
                  value={selectedRule}
                  select={select}
                  muiListSubheader={muiListSubheader}
                  sortedSystemGeneratedRulesList={
                    sortedSystemGeneratedRulesList
                  }
                  muiMenuItem={muiMenuItem}
                  sortingRulesList={sortingRulesList}
                  skeleton={skeleton}
                />
              </FormControl>
              {isAnalyticMetric && (
                <FormControl sx={bodyWrap}>
                  <label>
                    <Typography variant="subHeader">
                      {intl.formatMessage({
                        id: "automationRulesModal.sorting",
                        defaultMessage: "Sorting Period",
                      })}
                    </Typography>
                  </label>
                  <DropdownMenu
                    menuId="SortingToolsAnalytic"
                    value={selectedSortPeriod}
                    changeHandler={handleSortPeriod}
                    periodTypes={periodTypes}
                  />
                </FormControl>
              )}

              <ButtonComponent
                color="primary"
                variant="contained"
                disabled={selectedRule !== "" ? false : true}
                onClick={handleSortData}
              >
                Sort
              </ButtonComponent>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SortingTools);
