import { Product } from "store/product/ProductTypes";
import {
  FETCH_PRODUCT_ALERTS_LIST,
  FETCH_PRODUCTS_DETAILS,
  productAlertsList,
} from "./ProductAlertsListType";

export interface ProductAlertsListState {
  productAlertsList: productAlertsList[];
  isFetchingProductAlertsList: boolean;
}

const initialState: ProductAlertsListState = {
  productAlertsList: [],
  isFetchingProductAlertsList: false,
};

export const ProductAlertsListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCT_ALERTS_LIST.REQUEST: {
      return {
        ...state,
        isFetchingProductAlertsList: true,
      };
    }
    case FETCH_PRODUCT_ALERTS_LIST.SUCCESS: {
      const productAlertsList = action.payload.results as productAlertsList[];
      const sortedProductAlertsList = productAlertsList?.sort((a, b) =>
        b.jobStartTime.localeCompare(a.jobStartTime),
      );
      return {
        ...state,
        isFetchingProductAlertsList: false,
        productAlertsList: sortedProductAlertsList,
      };
    }
    case FETCH_PRODUCT_ALERTS_LIST.FAILURE: {
      return {
        ...state,
        isFetchingProductAlertsList: false,
        productAlertsList: [],
      };
    }
    case FETCH_PRODUCTS_DETAILS.SUCCESS: {
      const productDetails = action.payload.results;
      const productAlertsList = state.productAlertsList.map((alert) => ({
        ...alert,
        SKUs: alert.SKUs.map((sku) => ({
          ...sku,
          productName:
            productDetails.find(
              (product: Product) => product.productId === sku.productId,
            )?.cachedProduct?.product.name ?? sku.productName,
        })),
      }));
      return {
        ...state,
        productAlertsList: productAlertsList,
      };
    }
    default:
      return state;
  }
};
