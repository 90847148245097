import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material";

export const StyledDiv = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "1rem",
});

export const StyledSpan = styled("span")({
  marginRight: 10,
  fontSize: 20,
  fontWeight: 600,
});

export const styles = (theme: Theme) => {
  return {
    iconButton: {
      textDecoration: "underline",
      color: theme.palette.neutral.blueColorDark,
    },
    overlay: {
      position: "fixed" as const,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 99999,
      cursor: "pointer",
    },
    table: {
      "& .MuiDataGrid-columnHeaders": {
        background: "linear-gradient(#fff, #efefef)",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
      },
      "& .MuiDataGrid-cell": {
        paddingLeft: "10px",
      },
      "& .data-grid-header": {
        background: "none",
      },
      "& .MuiDataGrid-cell--editing": {
        boxShadow: "none !important", // Remove box shadow
        backgroundColor: "inherit !important", // Remove background color
      },
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        flexDirection: "row !important",
        paddingLeft: "10px !important",
      },
      "& .MuiDataGrid-cell.data-grid-cell": {
        justifyContent: "center",
      },
    },
    precedence: {
      width: "100px !important",
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.neutral.white,
        border: "1px solid #ccc",
        textAlign: "center",
        fontWeight: "bold",
        color: theme.palette.neutral.darkCharcoal,
        height: "51.4px !important",
      },
    },
    grid: {
      "& .MuiGrid-container": { width: "auto" },
    },
    form: {
      margin: "20px 20px 20px 20px",
    },
    label: {
      fontSize: "16px",
      color: theme.palette.neutral.black,
      fontWeight: 400,
      fontFamily: "Helvetica",
    },
    select: { "& .MuiSelect-root": { width: "325px !important" } },
    precedenceCell: {
      padding: "8px 12px",
      backgroundColor: theme.palette.neutral.white,
      border: "1px solid #ccc",
      borderRadius: "0px",
      textAlign: "center",
      fontWeight: "bold",
      color: theme.palette.neutral.darkCharcoal,
      width: "149px",
      maxWidth: "170px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    precedenceTextField: {
      width: "100%",
      height: "100%",
      "& .MuiInputBase-root": {
        fontWeight: "bold",
        color: theme.palette.neutral.darkCharcoal,
        boxShadow: "none",
      },
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
    },
    loaderStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formModalStyle: { minHeight: "420px" },
    fetchingBadgeDetails: { color: theme.palette.neutral.black },
  };
};
