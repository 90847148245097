import React from "react";
import { useIntl } from "react-intl";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
// import Link from "@mui/material/Link";
import { BusinessRulesListDetails } from "../../../../store/businessRules-list/BusinessRulesListTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessRulesDetailsById,
  loadAttributesList,
} from "../../../../store/businessRules-list/BusinessRulesListActions";
import AppState from "../../../../store/AppState";
import { selectIsLoading } from "../../../../store/loader/LoaderSelectors";
import { setModalState } from "../../../../store/modal/ModalActions";
import DeleteModal from "../SortingRules/DeleteModal/DeleteSortingRuleModal";
import { classicTheme } from "../../../../styles/themes/classic";
import ButtonComponent from "../../../../components/common/ButtonComponent";
import { AdapterInfoObject } from "utils/AppConfigUtils";
import { selectAdapterInfo } from "store/adapter-config/AdapterConfigSelectors";
import { selectCurrentStoreId } from "store/store-list/StoreListSelectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderClass: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
    },
    loaderText: {
      marginRight: 20,
    },
    editBtn: {
      color: "#337ab7 !important",
      textDecoration: "none !important",
      "&:hover": {
        textDecorationColor: "#337ab7 !important",
        textDecoration: "underline !important",
      },
    },
    deleteBtn: {
      color: "#d9534f !important",
      textDecoration: "none !important",
      "&:hover": {
        textDecorationColor: "#d9534f !important",
        textDecoration: "underline !important",
      },
    },
  }),
);

const DataGridUI = ({ businessRulesList, isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [ruleId, setRuleId] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const currentStoreId = useSelector(selectCurrentStoreId);
  const isLoadingData = useSelector((state: AppState) =>
    selectIsLoading(state, "GET_BUSINESS_RULES_BY_ID_LOADER"),
  );
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const isPostAttributesCall = adapterInfo?.operations?.includes(
    "POST /v1/{accountId}/product-attribute-definitions/query",
  ) as boolean;
  const rows: BusinessRulesListDetails[] = businessRulesList.length
    ? businessRulesList
    : [];

  const handleUpdateBusinessRulesDetails = (ruleId) => {
    if (!isPostAttributesCall) {
      dispatch(loadAttributesList({ storeId: currentStoreId }));
    }
    dispatch(
      setModalState("AttributeSortingRuleModal", true, { ruleId: ruleId }),
    );
    dispatch(getBusinessRulesDetailsById(ruleId));
    return;
  };

  const handleDeleteByRuleId = (ruleId, name) => {
    dispatch(setModalState("DeleteModal", true));
    setRuleId(ruleId);
    setName(name);
    return;
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: intl.formatMessage({
        id: "jobsListDataGridUI.name",
        defaultMessage: "Name",
      }),
      width: 400,
    },
    {
      field: "col2",
      headerName: intl.formatMessage({
        id: "dataGridUI.edit",
        defaultMessage: "Edit",
      }),
      width: 200,
      renderCell: (data) => {
        return (
          <ButtonComponent
            color="secondaryButtonColorCTABlue"
            variant="outlined"
            padding="8px 0px"
            onClick={() => handleUpdateBusinessRulesDetails(data.row.ruleId)}
          >
            {intl.formatMessage({
              id: "dataGridUI.edit",
              defaultMessage: "Edit",
            })}
          </ButtonComponent>
        );
      },
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "col3",
      headerName: intl.formatMessage({
        id: "dataGridUI.delete",
        defaultMessage: "Delete",
      }),
      width: 200,
      renderCell: (data) => {
        return (
          <ButtonComponent
            color="error"
            variant="outlined"
            padding="8px 0px"
            onClick={() => handleDeleteByRuleId(data.row.ruleId, data.row.name)}
          >
            {intl.formatMessage({
              id: "dataGridUI.delete",
              defaultMessage: "Delete",
            })}
          </ButtonComponent>
        );
      },
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  return (
    <div>
      {businessRulesList && businessRulesList.length > 0 && !isLoading && (
        <DataGrid
          getRowId={(row: BusinessRulesListDetails) => row.ruleId}
          rows={rows}
          columns={columns}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: classicTheme.palette.primary.main,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textTransform: "uppercase",
              fontWeight: "bold",
            },
          }}
        />
      )}
      {isLoading ? (
        <Box className={classes.loaderClass}>
          <Box className={classes.loaderText}>Loading data...</Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        businessRulesList.length === 0 && (
          <Box className={classes.loaderClass}>
            <Typography variant="h5">
              <b>
                {intl.formatMessage({
                  id: "sortingRules.noSortingRulesFoundText",
                  defaultMessage: "No sorting rules found",
                })}
              </b>
            </Typography>
          </Box>
        )
      )}
      {isLoadingData && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      <DeleteModal ruleId={ruleId} name={name} />
    </div>
  );
};

export default DataGridUI;
