import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeFindProductsSubscriptionsAndResetState } from "../../store/find-products/FindProductsActions";
import AppState from "../../store/AppState";
import FormControl from "@material-ui/core/FormControl";
import { selectAllCatalogs } from "../../store/catalog/CatalogSelectors";
import { LoadTopCategoriesByCatalogIdForFindProducts } from "../../store/category/CategoryActions";
import DropdownMenu from "../common/DropdownMenu";

interface Props {
  storeId: string;
  catalog: string;
  localeId: string;
  setCatalog: React.Dispatch<React.SetStateAction<string>>;
  setCurrentModalSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

const FindProductsCatalogSelection: React.FC<Props> = (props) => {
  const {
    storeId,
    catalog,
    localeId,
    setCatalog,
    setCurrentModalSelectedCategory,
  } = props;

  const catalogList: { name: string; id: string; code: string }[] = useSelector(
    (state: AppState) => selectAllCatalogs(state),
  );

  const dispatch = useDispatch();

  const handleCatalogChange = (event: any) => {
    const changeEvent = event as React.ChangeEvent<{ value: string }>;
    dispatch(closeFindProductsSubscriptionsAndResetState(catalog));
    setCatalog(changeEvent.target.value);
    if (changeEvent.target.value !== "") {
      setCurrentModalSelectedCategory("");
      dispatch(
        LoadTopCategoriesByCatalogIdForFindProducts(
          changeEvent.target.value,
          localeId,
          storeId,
        ),
      );
    }
  };

  return (
    <FormControl>
      <DropdownMenu
        menuId="FindProductsCatalogSelection"
        value={catalog}
        changeHandler={handleCatalogChange}
        catalogList={catalogList}
      />
    </FormControl>
  );
};

export default React.memo(FindProductsCatalogSelection);
