import React, { useMemo, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  componentClasses,
  StyledCard,
  StyledDiv,
  StyledTypography,
} from "./ColorManagementModalStyled";
import { ColorManagementProductData } from "../../store/color-management/ColorManagementTypes";
import {
  useDrag,
  useDrop,
  DropTargetMonitor,
  DragSourceMonitor,
} from "react-dnd";
import { XYCoord } from "dnd-core";
import { PLATFORM_DEFAULTS } from "../../utils/DefaultConfigs";
import PublishControl from "../PublishControl";
import { selectCurrentCategory } from "../../store/category/CategorySelectors";
import {
  getProductLeadingColorImageSFCC,
  getProductLeadingImageOnInitialLoad,
  isAbsolutePath,
} from "../../utils/ProductUtil";
import { UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG } from "../../store/color-management/ColorManagementTypes";
import { CircularProgress } from "@mui/material";
import {
  updateCategoryLeadingColorForProduct,
  resetUpdatingLeadingColorCompletedState,
  setLeadingColorId,
} from "../../store/color-management/ColorManagementActions";
import {
  selectLeadingColorId,
  selectisIsUpdatingLeadingColor,
} from "../../store/color-management/ColorManagementSelectors";
import { useIntl } from "react-intl";
import { getEmptyImage } from "react-dnd-html5-backend";
import {
  selectConfigValue,
  selectProductImagePrefix,
} from "../../store/app-config/AppConfigSelectors";
import AppState from "../../store/AppState";
import { AdapterInfoObject } from "../../utils/AppConfigUtils";
import { selectAdapterInfo } from "../../store/adapter-config/AdapterConfigSelectors";
import { removeLeadingImageForProductInCategory } from "../../store/leading-image/LeadingImageActions";
import { selectLeadingImageUrlForProductInCategory } from "../../store/leading-image/LeadingImageSelectors";
import NoImageIcon from "../../assets/NoImage.png";
import { selectProductFetched } from "../../store/product/ProductSelectors";
import ConfirmationLeadingColor from "./ConfirmationLeadingColor";
import { setModalState } from "../../store/modal/ModalActions";
import { matchLeadingImage } from "../../utils/ColorUtils";
import TooltipComponent from "components/common/ToolTip";
import { selectCurrentLocale } from "store/store-list/StoreListSelectors";
import { CDN_WIDGET_URL, removeDotFromStart } from "utils/WidgetUtil";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the container */
  align-items: center;
  overflow: hidden;
  margin: auto;
  width: 155px;
  height: 158px;
  & .image {
    width: 135px;
    height: 138px;
    margin: auto;
    object-fit: fill;
  }
  & .baseImage {
    width: 135px;
    height: 138px;
    object-fit: fill;
  }
`;

const ImageContainerNonBase = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the container */
  align-items: center;   
  overflow: hidden;
  margin: auto;
  width: 89px;
  height: 91px;
  & .image {
    width: 69px;
    height: 70px;
    object-fit: fill;
  }
  }
`;

interface Props {
  product: ColorManagementProductData | undefined;
  code?: string;
  baseProductId?: string;
  hasSequenceableColors?: boolean;
  selectedOverlay: string;
  height?: string;
  isColumn?: boolean;
  price?: string;
  index?: Number;
  storeId?: string;
  onItemDragStart?: (dragColor: DragItem) => void;
  onDragComplete?: (dragColor: DragItem) => void;
  onMove?: (
    dragColor: DragItem,
    hoverId: Number | undefined,
    dropId: Number | undefined,
    pointerOffset: XYCoord | null,
  ) => void;
  leadingColor?: string;
  updatedBaseProductLeadingColorImage?: string;
  isPublished?: boolean;
  hideSetUnsetColorText?: boolean;
  setshowNotPublishedText?: (value: boolean) => void; // Update the type to accept a function
  showNotPublishedText?: boolean;
  baseProductPrice?: string;
}

interface DragItem {
  type: string;
  product: ColorManagementProductData | undefined;
  index: Number | undefined;
  isPublished: Boolean;
}

const ColorManagementProductItem: React.FC<Props> = (props) => {
  const {
    product,
    isColumn,
    index,
    price,
    onMove,
    onDragComplete,
    onItemDragStart,
    baseProductId,
    leadingColor,
    updatedBaseProductLeadingColorImage,
    hasSequenceableColors,
    isPublished,
    setshowNotPublishedText,
    hideSetUnsetColorText,
    showNotPublishedText,
    baseProductPrice,
    ...otherProps
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const currentCategoryId = useSelector(selectCurrentCategory) || "";
  const [isLoading, setIsLoading] = useState(false);
  const [showLeadingColorText, setShowLeadingColorText] = useState(false);
  const [isChecked, setIsChecked] = useState(isPublished);
  const isUpdatingLeadingColorCompleted = useSelector(
    selectisIsUpdatingLeadingColor,
  );

  const productId = baseProductId ?? "";
  const productImageData = useSelector((state: AppState) =>
    selectProductFetched(state, productId),
  );

  const storeId = props?.storeId ?? "default";
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const leadingColorId = useSelector(selectLeadingColorId);
  const ecomPlatformType = adapterInfo?.adapterId?.toUpperCase() ?? "";
  const platformDefaults = PLATFORM_DEFAULTS[ecomPlatformType];
  const localeCode = useSelector(selectCurrentLocale);
  const productImagePrefix = useSelector((state: AppState) =>
    selectProductImagePrefix(state, storeId),
  );
  const storeAndAccountInventory = useSelector((state: AppState) =>
    selectConfigValue(state, "inventoryThreshold"),
  );
  const inventoryThreshold = parseInt(
    storeAndAccountInventory !== ""
      ? storeAndAccountInventory
      : platformDefaults?.inventoryThreshold ?? "",
  );
  const isLeadingColorSupported = adapterInfo?.capabilities?.includes(
    "leading-color",
  ) as boolean;

  const [, drop] = useDrop({
    accept: "COLOR",
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !hasSequenceableColors) {
        return;
      }

      const dropItemIndex = Number(index);
      const currentItemIndex = Number(item?.index);
      const clientOffset = monitor.getClientOffset();
      onMove?.(item, currentItemIndex, dropItemIndex, clientOffset);
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag<
    DragItem,
    unknown,
    { isDragging: boolean }
  >({
    item: {
      type: "COLOR",
      product: product,
      index: index,
      isPublished: Boolean(isChecked), // Use `isChecked` to track the state
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    begin: (monitor: DragSourceMonitor) => {
      const draggedColor = product;

      // Set the `isChecked` value of the dragged item
      onItemDragStart?.({
        type: "COLOR",
        product: draggedColor,
        index: index,
        isPublished: Boolean(isChecked),
      });

      return {
        type: "COLOR",
        product: draggedColor,
        index,
        isPublished: Boolean(isChecked),
      };
    },
    end: (item, monitor) => {
      // Update `isChecked` based on the dropped item
      const droppedItem = monitor.getItem();
      if (
        droppedItem &&
        droppedItem.product &&
        droppedItem.product.name === product?.name
      ) {
        setIsChecked(droppedItem.isPublished); // Update `isChecked` when the product is dropped
      }

      onDragComplete?.(droppedItem);
    },
  });
  useEffect(() => {
    // Set the initial state based on the passed `isPublished` value
    setIsChecked(Boolean(isPublished));
  }, [isPublished]);

  useEffect(() => {
    // this useEffect hides the default preview
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const leadingImageThumbnail: string | null =
    useSelector((state: AppState) =>
      selectLeadingImageUrlForProductInCategory(
        state,
        baseProductId || "",
        currentCategoryId,
      ),
    ) ??
    getProductLeadingImageOnInitialLoad(productImageData, currentCategoryId) ??
    null;

  const updatePublishFlag = (flag: boolean) => {
    if (!product) {
      if (process.env.NODE_ENV !== "production") {
        console.error(
          "ColorManagementProductItem updatePublishFlag called without colorData",
        );
      }
      return;
    }
    const payload = {
      productId: baseProductId as string,
      colorId: product?.colorId as string,
      storeId: storeId,
      isPublished: flag,
      localeId: localeCode,
    };
    dispatch({
      type: UPDATE_COLOR_MANAGEMENT_PUBLISH_FLAG.REQUEST,
      payload,
    });
    const isLeadingImageMatch = matchLeadingImage(
      leadingImageThumbnail,
      productImageData,
      product.code,
    );
    if (!flag && baseProductId) {
      const isLeadingColor = leadingColor && leadingColor !== "";
      const isLeadingImageThumbnail =
        leadingImageThumbnail && leadingImageThumbnail !== "";
      if (isLeadingColor && leadingColor === product?.code) {
        const leadingColorPayload = {
          leadingColorId: "",
          categoryId: currentCategoryId,
          productId: baseProductId ?? "",
          thumbnail:
            leadingColor !== null && product?.thumbnail
              ? product?.thumbnail
              : "",
        };
        dispatch(updateCategoryLeadingColorForProduct(leadingColorPayload));
      }
      if (isLeadingImageThumbnail) {
        if (
          leadingImageThumbnail === product.thumbnail ||
          isLeadingImageMatch
        ) {
          dispatch(
            removeLeadingImageForProductInCategory({
              productId: baseProductId || "",
              categoryId: currentCategoryId,
            }),
          );
        }
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    updatePublishFlag(checked);
  };

  useEffect(() => {
    if (isUpdatingLeadingColorCompleted) {
      setIsLoading(false);
      dispatch(resetUpdatingLeadingColorCompletedState());
    }
  }, [isUpdatingLeadingColorCompleted, dispatch]);
  const productLeadingColorImage = useMemo(() => {
    if (
      !updatedBaseProductLeadingColorImage &&
      updatedBaseProductLeadingColorImage !== "" &&
      product?.categorySpecific
    ) {
      return baseProductId && product?.categorySpecific?.length > 0
        ? getProductLeadingColorImageSFCC(product, currentCategoryId)
        : "";
    } else {
      return updatedBaseProductLeadingColorImage;
    }
  }, [
    product,
    currentCategoryId,
    baseProductId,
    updatedBaseProductLeadingColorImage,
  ]);

  const productImage = productLeadingColorImage || product?.thumbnail;
  const imagePath = isAbsolutePath(productImage)
    ? productImage
    : productImagePrefix + productImage;

  const hasLeadingColorForProduct =
    (leadingColor && leadingColor === product?.colorId) || false;

  const handleLeadingColorClick = (leadingColor, target) => {
    dispatch(setLeadingColorId(leadingColor));
    if (product && !target.type) {
      const updateLeadingColor = () => {
        setIsLoading(true);
        const payload = {
          leadingColorId: leadingColor,
          categoryId: currentCategoryId,
          productId: baseProductId ?? "",
          thumbnail:
            leadingColor !== null && product?.thumbnail
              ? product?.thumbnail
              : "",
        };
        dispatch(updateCategoryLeadingColorForProduct(payload));
      };

      if (leadingImageThumbnail === "") {
        updateLeadingColor();
      } else {
        const isLeadingImgMatch = matchLeadingImage(
          leadingImageThumbnail,
          productImageData,
          product.code,
        );
        const isLeadingImage =
          leadingImageThumbnail === product.thumbnail || isLeadingImgMatch;
        if (!isLeadingImage) {
          dispatch(setModalState("ConfirmationLeadingColor", true));
        } else {
          updateLeadingColor();
          if (leadingColor === null && leadingImageThumbnail)
            dispatch(
              removeLeadingImageForProductInCategory({
                productId: baseProductId || "",
                categoryId: currentCategoryId,
              }),
            );
        }
      }
    }
  };

  useEffect(() => {
    if (isConfirm) {
      setIsLoading(true);
      dispatch(
        removeLeadingImageForProductInCategory({
          productId: baseProductId || "",
          categoryId: currentCategoryId,
        }),
      );
      if (leadingColorId !== "") {
        const payload = {
          leadingColorId: leadingColorId,
          categoryId: currentCategoryId,
          productId: baseProductId ?? "",
          thumbnail:
            leadingColor !== null && product?.thumbnail
              ? product?.thumbnail
              : "",
        };
        dispatch(updateCategoryLeadingColorForProduct(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm, dispatch]);

  const renderProductImage = (
    isColumn,
    productImage,
    product,
    componentClasses,
  ) => {
    if (isColumn) {
      return (
        <ImageContainerNonBase>
          {productImage ? (
            <img
              className="image"
              src={productImage}
              alt={product?.name}
              draggable={false}
            />
          ) : (
            <StyledDiv className={componentClasses.emptyImageContainer}>
              <img
                className="image"
                src={`${CDN_WIDGET_URL}${removeDotFromStart(NoImageIcon)}`}
                alt={product?.name}
                draggable={false}
              />
            </StyledDiv>
          )}
        </ImageContainerNonBase>
      );
    } else {
      return (
        <ImageContainer>
          {productImage ? (
            <img
              className={
                productLeadingColorImage !== "" && !isColumn
                  ? "baseImage"
                  : "image " + componentClasses.noImage
              }
              src={productImage}
              alt={product?.name}
              draggable={false}
            />
          ) : (
            <StyledDiv className={componentClasses.emptyImageContainer}>
              <img
                className="image"
                src={`${CDN_WIDGET_URL}${removeDotFromStart(NoImageIcon)}`}
                alt={product?.name}
                draggable={false}
              />
            </StyledDiv>
          )}
        </ImageContainer>
      );
    }
  };

  const renderProductquantity = () => (
    <StyledDiv
      className={componentClasses.leadingColorText}
      leadingColor={hasLeadingColorForProduct}
      isPublished={isPublished}
    >
      {isLeadingColorSupported &&
        (!showLeadingColorText && hasLeadingColorForProduct
          ? intl.formatMessage({
              id: "colorManagementModal.leadingColor",
              defaultMessage: "Leading Color",
            })
          : showLeadingColorText && hasLeadingColorForProduct
            ? intl.formatMessage({
                id: "colorManagementModal.unsetLeadingColor",
                defaultMessage: "Unset as Leading Color",
              })
            : intl.formatMessage({
                id: "colorManagementModal.setLeadingColor",
                defaultMessage: "Set as Leading Color",
              }))}
    </StyledDiv>
  );

  const renderProductInfo = (
    isColumn,
    product,
    componentClasses,
    price?: string,
  ) => {
    const productPrice = isColumn
      ? price
        ? price
        : ""
      : baseProductPrice
        ? baseProductPrice
        : "";
    return (
      <StyledDiv
        className={componentClasses.imageProductCodeDiv}
        isColumn={isColumn}
      >
        <TooltipComponent tooltipTitle={product?.name || ""} placement="bottom">
          <StyledTypography
            className={componentClasses.productCodes}
            isColumn={isColumn}
          >
            {product?.name || ""}
          </StyledTypography>
        </TooltipComponent>
        <TooltipComponent tooltipTitle={`${product?.code}`} placement="bottom">
          <StyledTypography
            className={componentClasses.productPrice}
            isColumn={isColumn}
          >
            {`${product?.code}`}
          </StyledTypography>
        </TooltipComponent>
        <TooltipComponent tooltipTitle={productPrice} placement="bottom">
          <StyledTypography
            className={componentClasses.productTypeBase}
            isColumn={isColumn}
          >
            {productPrice}
          </StyledTypography>
        </TooltipComponent>
      </StyledDiv>
    );
  };

  return (
    <StyledDiv>
      {isLoading && isColumn && (
        <StyledDiv className={componentClasses.overlay}>
          <CircularProgress />
        </StyledDiv>
      )}
      <StyledDiv
        {...(isColumn
          ? {
              ref,
              style: { opacity },
              onClick:
                isLeadingColorSupported && isPublished
                  ? hasLeadingColorForProduct
                    ? (event) => handleLeadingColorClick(null, event.target)
                    : (event) =>
                        handleLeadingColorClick(product?.colorId, event.target)
                  : undefined,
              onMouseEnter: () => {
                if (isLeadingColorSupported) setShowLeadingColorText(true);
                if (isLeadingColorSupported && setshowNotPublishedText) {
                  setshowNotPublishedText(
                    (!hideSetUnsetColorText && !isPublished) ?? true,
                  );
                }
              },
              onMouseLeave: () => {
                if (isLeadingColorSupported) setShowLeadingColorText(false);
                if (isLeadingColorSupported && setshowNotPublishedText) {
                  setshowNotPublishedText(false);
                }
              },
            }
          : {})}
      >
        <StyledCard
          variant="outlined"
          className={
            !isColumn
              ? componentClasses.baseProductCard
              : componentClasses.productCard
          }
          isPublished={isPublished}
          leadingColor={hasLeadingColorForProduct}
          {...otherProps}
          showNotPublishedText={showNotPublishedText}
        >
          <StyledDiv className={componentClasses.cardContentDiv}>
            {isColumn && renderProductquantity()}
            {!isColumn && (
              <StyledTypography className={componentClasses.stockBase}>
                INV:{product?.stock}
              </StyledTypography>
            )}
            {isColumn && Number(product?.stock) <= 0 && (
              <StyledDiv className={componentClasses.outOfStock}>
                <StyledTypography className={componentClasses.stock}>
                  {intl.formatMessage({
                    id: "colorManagement.noStockItem",
                    defaultMessage: "No stock Item",
                  })}
                </StyledTypography>
              </StyledDiv>
            )}

            {isColumn &&
              Number(product?.stock) <= inventoryThreshold &&
              Number(product?.stock) > 0 && (
                <StyledDiv className={componentClasses.belowInventoryThreshold}>
                  <StyledTypography className={componentClasses.stock}>
                    INV:{product?.stock}
                  </StyledTypography>
                </StyledDiv>
              )}

            {isColumn &&
              Number(product?.stock) > inventoryThreshold &&
              Number(product?.stock) > 0 && (
                <StyledDiv className={componentClasses.thresholdRange}>
                  <StyledTypography className={componentClasses.stock}>
                    INV:{product?.stock}
                  </StyledTypography>
                </StyledDiv>
              )}

            {renderProductImage(isColumn, imagePath, product, componentClasses)}

            {renderProductInfo(isColumn, product, componentClasses, price)}

            {isColumn && (
              <PublishControl
                onChange={handleCheckboxChange}
                flag={isChecked}
                product={product}
              />
            )}
          </StyledDiv>
        </StyledCard>
      </StyledDiv>
      <ConfirmationLeadingColor setIsConfirm={setIsConfirm} />
    </StyledDiv>
  );
};

export default ColorManagementProductItem;
