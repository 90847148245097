import { withStyles } from "@material-ui/core/styles";
import { Container, Theme } from "@mui/material";
import styled from "styled-components";
import Chip, { ChipProps } from "@mui/material/Chip";

export const useStyles = (theme: Theme) => {
  return {
    selectedBoxStyle: {
      border: "2px dashed " + theme.palette.text.primary,
      borderRadius: 3,
      cursor: "pointer",
      padding: 3,
    },
    defaultBorder: {
      marginRight: 2,
      width: 25,
      height: 25,
      cursor: "pointer",
      padding: 4.5,
    },
    displaySWatch: {
      display: "block",
    },
    hideSwatch: {
      display: "none",
    },
    icon: {
      marginRight: 0,
    },
    popoverStyles: {
      "& .MuiPaper-root": {
        maxWidth: 122,
        maxHeight: 124,
        padding: "10px !important",
        border: "1px solid" + theme.palette.secondary.main,
      },
    },
    swatchBox: {
      display: "flex",
    },
    productType: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left !important" as any,
      paddingBottom: "0px !important" as any,
      width: "75%",
    },
    productCode: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "75%",
    },
    smallFontSize: {
      fontSize: "11px",
    },
    colorSwatchImageContainer: {
      height: "25px",
      width: "25px",
      display: "inline-block",
    },
    colorSwatchImage: {
      width: "100%",
      height: "100%",
      borderRadius: "2px",
    },
    redBorder: (isSelected: boolean) => ({
      border: isSelected ? "2px dashed red" : "2px solid red", // Conditionally set border type
      borderRadius: "2px",
      padding: 3,
    }),
    yellowBorder: (isSelected: boolean) => ({
      border: isSelected
        ? "2px dashed " + theme.palette.neutral.yellow
        : "2px solid " + theme.palette.neutral.yellow,
      borderRadius: "2px",
      padding: 3,
    }),
    greyBorder: (isSelected: boolean) => ({
      border: "2px solid " + theme.palette.neutral.white,
      borderRadius: "2px",
      padding: 3,
    }),
    ratingsContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      paddingLeft: "13%",
    },
    ratingContainerMargin: {
      marginTop: "-2px",
    },
    ratingTextPadding: {
      paddingLeft: "5px",
      marginTop: "-1px",
    },
    scoreTextPadding: {
      paddingTop: "5px",
      paddingBottom: "6px",
      paddingLeft: "5px",
    },
    swatchButton: {
      paddingTop: 1,
    },
    position: {
      display: "none",
    },
    swatch: {
      marginRight: 3,
    },
    inventoryColor: {
      color: "#B82A1D !important",
    },
    substitutionIcon: {
      marginLeft: "10px",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
    substitutionText: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      justifySelf: "start",
    },
    boostBuryIcon: (isEnabled: boolean) => ({
      cursor: isEnabled ? "pointer" : "not-allowed",
    }),
  };
};
interface BadgeChipProps extends ChipProps {
  marginTop?: string | undefined;
}
export const BadgeChip = styled(Chip)<BadgeChipProps>`
  ${({ theme, marginTop }) => `
  height: 18px !important;
  width: 100px !important;
  margin-top: ${marginTop || "10px"} !important;
  background-color: ${theme.palette.unifiedSortingRuleModalMenu.main} !important;
  border-radius: 0px 5px 5px 0px;
  padding: 0px; /* Add padding for better text spacing */
  font-size: 9px !important; /* Small text size */
  color: white !important; /* White text color */
  font-weight: bold !important;
  position: absolute;
  margin-left: -10px;
  text-transform: uppercase; /* Optional for label formatting */
    /* Customize the icon inside Chip */
    & .MuiChip-icon {
      height: 10px !important;
      width: 10px !important;
      fill: white !important; /* White icon color */
    }
  `}
`;
export const StyledProductTileContent = styled.div<StyledProductTileContentProps>`
  ${({ theme, isSMAIOverlay }) => `
      padding-bottom: ${isSMAIOverlay ? "0px" : "15px"};
      padding-left: 13%;
      & .catentryType {
        font-weight: bold;
      }
      & .inventory{
        color: ${theme.palette.secondary.main};
      } 
 `}
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-top: 10px;
`;

export const StyledLoadingScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin-bottom: 10px;
`;

export const ScrollableContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flexwrap: wrap;
  justify-content: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledCircularDiv = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  cursor: pointer;
`;

export const StyledContainer = withStyles({
  root: {
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: "auto",
    margin: "5px",
    width: "98% !important",
    backdropFilter: "blur(0.5px)",
  },
})(Container);

export const StyledSwatchDiv = styled.div`
  text-align: center;
  cursor: pointer;
  padding-top: 15px;
`;

interface StyledProductTileContentProps {
  isSMAIOverlay?: boolean;
}
