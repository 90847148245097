import { productClusterList } from "store/ai-cluster/AiClusterType";
import { ProductSequenceData } from "../store/product-list/ProductListTypes";
import { SmartSortValues } from "./Constants";
import { Product } from "../store/product/ProductTypes";

interface EnhancedProductData extends ProductSequenceData {
  cluster_score: number;
  rank: string;
  stock: number | null;
  launchDate: Date | null;
}

const createSimilarityMap = (
  productSimilarity: productClusterList[],
): Map<string, productClusterList> => {
  return new Map(productSimilarity.map((item) => [item.productId, item]));
};

const enhanceProduct = (
  product: ProductSequenceData,
  similarityData: productClusterList | undefined,
  productStock: number | null,
  productLaunchDate: Date | null,
): EnhancedProductData => {
  return {
    ...product,
    cluster_score: similarityData?.cluster_score ?? 0,
    rank: similarityData?.rank ?? "neutral",
    stock: productStock,
    launchDate: productLaunchDate,
  };
};

const partitionAndSortProducts = (
  products: EnhancedProductData[],
  filterCondition: (product: EnhancedProductData) => boolean,
  isReversed: boolean = false,
  sortByDate: boolean = false,
): ProductSequenceData[] => {
  const [matchingProducts, otherProducts] = products.reduce<
    [EnhancedProductData[], EnhancedProductData[]]
  >(
    ([matching, others], product) => {
      return filterCondition(product)
        ? [[...matching, product], others]
        : [matching, [...others, product]];
    },
    [[], []],
  );

  if (sortByDate) {
    matchingProducts.sort((a, b) => {
      const dateA = a.launchDate ? new Date(a.launchDate).getTime() : 0;
      const dateB = b.launchDate ? new Date(b.launchDate).getTime() : 0;
      return dateB - dateA;
    });
  } else {
    matchingProducts.sort((a, b) => b.cluster_score - a.cluster_score);
  }

  return isReversed
    ? [...otherProducts, ...matchingProducts]
    : [...matchingProducts, ...otherProducts];
};

const isNewProduct = (product: EnhancedProductData): boolean => {
  if (!product.launchDate) return false;
  const launchDate = new Date(product.launchDate);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate.getTime() - launchDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 30;
};

// Get Updated Product List
export const getUpdatedProductList = (
  products: ProductSequenceData[],
  productSimilarity: productClusterList[],
  allProducts: { [key: string]: Product },
  sortType: string,
): ProductSequenceData[] => {
  const similarityMap = createSimilarityMap(productSimilarity);
  const productsWithScores: EnhancedProductData[] = products.map((product) =>
    enhanceProduct(
      product,
      similarityMap.get(product.productId),
      allProducts[product.productId]?.stock ?? null,
      allProducts[product.productId]?.launchDate ?? null,
    ),
  );

  // sorting based on sortType
  switch (sortType) {
    case SmartSortValues.boost:
      return partitionAndSortProducts(
        productsWithScores,
        (product) => product.rank === SmartSortValues.boost,
      );

    case SmartSortValues.bury:
      return partitionAndSortProducts(
        productsWithScores,
        (product) => product.rank === SmartSortValues.bury,
        true,
      );

    case SmartSortValues.outOfStock:
      return partitionAndSortProducts(
        productsWithScores,
        (product) => product.stock === 0,
        true,
      );

    case SmartSortValues.newProducts:
      return partitionAndSortProducts(
        productsWithScores,
        (product) => isNewProduct(product),
        false,
        true,
      );

    default:
      return products;
  }
};
