import App from "./App";
import { render } from "react-dom";
import { StyleExtractor } from "./styles";

interface Props {
  [key: string]: any;
}

export const WIDGET_TAG_NAME = "smart-merchandiser";

const STYLE_ID = "dynamic-styles";
function updateStyles(root: ShadowRoot, styles: string[]) {
  const hardcodedStyles = styles.join("\n");

  const previousStyleElement = root.getElementById(STYLE_ID);

  if (previousStyleElement) {
    previousStyleElement.innerHTML += hardcodedStyles;
  } else {
    const styleElement = document.createElement("style");
    styleElement.id = STYLE_ID;
    styleElement.innerHTML = hardcodedStyles;

    root.appendChild(styleElement);
  }
}

// function injectFontAwesome(root: ShadowRoot) {
//   fetch(
//     "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.0-2/css/all.min.css",
//   )
//     .then((response) => response.text())
//     .then((css) => {
//       updateStyles(root, [css]);
//     });
// }

class SmartMerchandiser extends HTMLElement {
  private token: string = "";

  connectedCallback() {
    this.mount();
  }

  disconnectedCallback() {}

  mount() {
    const props: Props = {
      ...this.getProps(),
      ...this.getEventProps(),
    };

    const children = this.parseHtmlToReact(this.innerHTML);

    const mountPoint = document.createElement("div");
    mountPoint.id = "smart-merchandiser";

    const root = this.attachShadow({ mode: "open" });

    root.append(...children);
    root.appendChild(mountPoint);

    // Usage
    const styleExtractor = new StyleExtractor((newStyles: string[]) => {
      updateStyles(root, newStyles);
    });

    // Initial extraction and subscription to style changes
    styleExtractor.extractStyles().then((allStyles) => {
      updateStyles(root, allStyles);
    });

    render(<App {...props} />, mountPoint);
  }

  parseHtmlToReact(html: string) {
    const template = document.createElement("template");
    template.innerHTML = html;
    return Array.from(template.content.childNodes);
  }

  getProps(): Props {
    const props: Props = {};
    // List of allowed attributes
    const allowedAttributes = ["token"];
    Array.from(this.attributes).forEach((attr) => {
      const propName = attr.name.toLowerCase();
      if (allowedAttributes.includes(propName)) {
        if (propName === "token") {
          if (this.token !== attr.value && attr.value !== "") {
            this.token = attr.value;
            props[propName] = this.convert(attr.value);

            this.removeTokenAttribute();
          }
          return;
        } else {
          props[propName] = this.convert(attr.value);
        }
      }
    });
    return props;
  }

  getEventProps(): Props {
    const eventProps: Props = {};
    // List of allowed event handlers
    const allowedEvents = [];
    Array.from(this.attributes).forEach((attr) => {
      const eventName = attr.name.toLowerCase();
      if (allowedEvents.includes(eventName as never)) {
        eventProps[eventName] = attr.value; // Sanitize it
      }
    });
    return eventProps;
  }

  convert(value: string): any {
    if (value === "true" || value === "false") {
      return value === "true";
    } else if (!isNaN(Number(value)) && value !== "") {
      return +value;
    } else if (/^{.*}$/.test(value)) {
      return JSON.parse(value);
    }
    return value;
  }

  private removeTokenAttribute() {
    const merchandiserTag = document.getElementsByTagName(WIDGET_TAG_NAME)[0];
    merchandiserTag.removeAttribute("token");
  }
}

export function registerSmartMerchandiser() {
  const customElements = window.customElements;

  if (!customElements.get(WIDGET_TAG_NAME)) {
    customElements.define(WIDGET_TAG_NAME, SmartMerchandiser);
  }
}
