import React, { SyntheticEvent, useEffect, useState } from "react";
import { FieldArray } from "formik";
import { StyledDiv, classes } from "./Styles";
import { Typography, Box } from "@mui/material";
import TextfieldWrapper from "../../../Textfield/TextFieldWrapper";
import SelectWrapper from "../../../Select/SelectWrapper";
import { Delete } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { scoringFormulaTypes } from "../../../../../utils/Constants";
import ButtonComponent from "../../../../common/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectAdapterInfo } from "store/adapter-config/AdapterConfigSelectors";
import { AdapterInfoObject } from "utils/AppConfigUtils";
import {
  fetchAttributesList,
  resetAttributesFlag,
  resetAttributesList,
  setSearchValue,
} from "store/businessRules-list/BusinessRulesListActions";
import {
  selectBusinessRuledDetailsById,
  selectIsAttributesListLoaded,
} from "store/businessRules-list/BusinessRuleslistSelectors";
import AutoComplete from "../../../../common/AutoComplete";

const AttributesForm = ({
  formik,
  handleAttributesValues,
  attributesList,
  handleMappedValues,
  handleRemoveSingleAttribute,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const adapterInfo: AdapterInfoObject = useSelector(selectAdapterInfo);
  const businessRulesDetailsObj = useSelector(selectBusinessRuledDetailsById);
  const isAttributesLoaded = useSelector(selectIsAttributesListLoaded);
  const isQueryAPIAvailable = adapterInfo?.operations?.includes(
    "POST /v1/{accountId}/product-attribute-definitions/query",
  ) as boolean;
  const handleAttributes = (value: string) => {
    if (businessRulesDetailsObj.ruleId === "") dispatch(resetAttributesList());
    if (value !== "") {
      setIsLoading(true);
      dispatch(setSearchValue(value));
      dispatch(
        fetchAttributesList({
          startWithKeyword: value,
        }),
      );
    }
  };
  useEffect(() => {
    if (isAttributesLoaded) {
      setIsLoading(false);
      dispatch(resetAttributesFlag());
    }
  }, [isAttributesLoaded, dispatch]);
  return (
    <StyledDiv className={classes.attributeFieldsParent}>
      <StyledDiv className={classes.attributeScoringFormula}>
        <SelectWrapper
          defaultValue={""}
          name="scoringFormula"
          label="Scoring Formula"
          options={scoringFormulaTypes}
          currentModalValue=""
          variant="outlined"
          size="small"
        />
      </StyledDiv>
      <div>
        <Typography fontWeight={600} mb={1.25}>
          {intl.formatMessage({
            id: "unifiedSortingRuleModal.attributes",
            defaultMessage: "Attributes",
          })}
        </Typography>
        <FieldArray name="attributes">
          {({ push, remove }) => (
            <Box>
              {formik.values.attributes.map((attribute, index) => (
                <div>
                  <StyledDiv
                    key={index}
                    className={classes.attributeSectionParent}
                  >
                    <AutoComplete
                      value={attribute.attr}
                      onChange={(_: SyntheticEvent, newValue) => {
                        formik.setFieldValue(
                          `attributes.${index}.attr`,
                          newValue,
                        );
                        handleAttributesValues(newValue);
                      }}
                      options={attributesList?.map(
                        (attribute) => attribute.label,
                      )}
                      label={
                        !isQueryAPIAvailable
                          ? intl.formatMessage({
                              id: "unifiedSortingRuleModal.search",
                              defaultMessage: "Search",
                            })
                          : intl.formatMessage({
                              id: "unifiedSortingRuleModal.searchByIdentifier",
                              defaultMessage: "Search by identifier",
                            })
                      }
                      name={`attributes.${index}.attr`}
                      onInputChange={
                        isQueryAPIAvailable ? handleAttributes : undefined
                      }
                      loading={!isQueryAPIAvailable ? false : isLoading}
                      noOptionsText={
                        !isQueryAPIAvailable
                          ? ""
                          : intl.formatMessage({
                              id: "unifiedSortingRuleModal.typeToSearch",
                              defaultMessage: "Type to search",
                            })
                      }
                      isAttributeForm={true}
                    />

                    <SelectWrapper
                      defaultValue={""}
                      currentModalValue=""
                      label="Value"
                      name={`attributes.${index}.value`}
                      options={handleMappedValues(
                        formik.values.attributes[index].attr,
                      )}
                      variant="outlined"
                      size="small"
                    />
                    <TextfieldWrapper
                      name={`attributes.${index}.points`}
                      label="Points"
                      type="number"
                      variant="outlined"
                      size="small"
                    ></TextfieldWrapper>
                    <StyledDiv
                      className={classes.removeAttrBtnParent}
                      onClick={() => {
                        if (formik.values.attributes.length === 1) {
                          handleRemoveSingleAttribute();
                          formik.setFieldValue(`attributes.${index}`, {
                            attr: "",
                            value: "",
                            points: null,
                          });
                          formik.setFieldTouched(`attributes.${index}`, false);
                          formik.setFieldValue("scoringFormula", "");
                          formik.setFieldTouched("scoringFormula", false);
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      <Delete color="error" />
                    </StyledDiv>
                  </StyledDiv>
                  <StyledDiv className={classes.border}></StyledDiv>
                </div>
              ))}
              <StyledDiv className={classes.btnDiv}>
                <ButtonComponent
                  color="tertiary"
                  variant="contained"
                  fullWidth={true}
                  padding="15px"
                  justifyContent="center"
                  onClick={() =>
                    push({
                      attr: "",
                      value: "",
                      points: "",
                    })
                  }
                >
                  {intl.formatMessage({
                    id: "unifiedSortingRuleModal.addAttributes",
                    defaultMessage: "Add Attributes",
                  })}
                </ButtonComponent>
              </StyledDiv>
            </Box>
          )}
        </FieldArray>
      </div>
    </StyledDiv>
  );
};

export default AttributesForm;
