import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const styles = () => {
  return {
    bodyWrap: {
      display: "flex",
      flexDirection: "row !important",
      alignItems: "center",
    },
  };
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-flex",
    },
    sortClass: {
      display: "flex",
      alignItems: "center",
    },
  }),
);
