import {
  CREATE_PRODUCT_BADGE,
  CreateProductBadgeAction,
  DELETE_BADGE,
  DeleteBadgeAction,
  GET_PRODUCT_BADGE,
  GetBadgeAction,
  LOAD_PRODUCT_BADGES_LIST,
  LoadProductBadgeListAction,
  LoadQueryBadgeAction,
  ProductBadgeData,
  QUERY_PRODUCT_BADGE,
  RESET_PRODUCT_BADGE_STATE,
  RESET_SELECTED_STORE,
  SET_SELECTED_STORE,
  UPDATE_BADGE_PRECEDENCE,
  UPDATE_PRODUCT_BADGE,
  UpdateBadgePrecedenceAction,
  UpdateProductBadgeAction,
} from "./ProductBadgeTypes";

export const LoadProductBadgeList = (): LoadProductBadgeListAction => {
  return {
    type: LOAD_PRODUCT_BADGES_LIST,
  };
};

export const QueryProductBadge = (
  productIds: string[],
): LoadQueryBadgeAction => {
  return {
    type: QUERY_PRODUCT_BADGE.REQUEST,
    payload: {
      productIds,
    },
  };
};

export const updateBadgePrecedence = (
  badgeDetails: ProductBadgeData,
): UpdateBadgePrecedenceAction => {
  return {
    type: UPDATE_BADGE_PRECEDENCE.REQUEST,
    payload: badgeDetails,
  };
};

export const deleteBadge = (badgeDetails: {
  badgeId: string;
  storeId: string;
}): DeleteBadgeAction => {
  return {
    type: DELETE_BADGE.REQUEST,
    payload: badgeDetails,
  };
};

export const getProductBadgeDetails = (badgeDetails: {
  badgeId: string;
  storeId: string;
}): GetBadgeAction => {
  return {
    type: GET_PRODUCT_BADGE.REQUEST,
    payload: badgeDetails,
  };
};

export const createProductBadge = (
  badgeDetails: ProductBadgeData,
): CreateProductBadgeAction => {
  return {
    type: CREATE_PRODUCT_BADGE.REQUEST,
    payload: badgeDetails,
  };
};

export const updateProductBadge = (
  badgeDetails: ProductBadgeData,
): UpdateProductBadgeAction => {
  return {
    type: UPDATE_PRODUCT_BADGE.REQUEST,
    payload: badgeDetails,
  };
};

export const resetProductBadgeState = () => {
  return {
    type: RESET_PRODUCT_BADGE_STATE,
  };
};

export const setSelectedStore = (storeId: string) => {
  return {
    type: SET_SELECTED_STORE,
    storeId,
  };
};

export const resetSelectedStore = () => {
  return {
    type: RESET_SELECTED_STORE,
  };
};
